@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
    --transition-short: all .2s ease-in-out;
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
}

.profile_wrapper{
    width: 100vw;
    height: 100vh;
    position: relative;
    padding-top: 0px;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
}

.profile_profile_bar{
    position: relative; 
    width: 100%;
}


.profile_bar{
    position: fixed;
    z-index: 5000;
    background: var(--orange);
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 2rem;
}

.partner_welcome{
    font-size: 1.5rem;
    color: white;
    font-weight: 600;
}

.welcome-greet {
    font-size: 1.2rem;
    font-weight: 300;
    color: var(--green);
}

.profile_logOut{
    padding: 0.3rem 0.7rem;
    font-size: 18px;
    font-weight: 300;
    background: var(--green);
    color: white;
    border: none;
    transition: ease-in .2s;
}

.profile_logOut:hover{
    background: white;
    color: var(--orange);
}

.profile_account_section{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.1rem;
    width: 100%;
    height: 100%;
    margin-top: 4%;
}

.profile_content{
    height: fit-content;
    position: relative;
    width: 100%;
}

.categories_div{
    margin-top: -0.3%;
    position: fixed;
    margin-bottom: 0.3%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    height: fit-content;
    width: 100%;
    background: white;
    padding: 0.3rem 0rem;
    z-index: 500;
}


.profile_category {
    font-size: 18px;
    font-weight: 400;
    padding: 2px 0.3rem;
    color: var(--green);
    background-color: var(--green);
    color: white;
    border: none;
    transition: linear 0.2s;
    width: 10%;
    cursor: pointer;
    border: 0.1px solid var(--orange);
}

.profile_category:hover,.profile_category:focus,.profile_category:active{
    color: var(--orange);
    background-color: white;
    border: 0.1px solid var(--orange);
}

/* .service_drop {
    position: absolute;
    min-width: 160px;
    z-index: 5000;
    top: 15%;
    left: 32%;
} */

/* .service_drop a {
    border: 1px solid rgba(0, 0, 0, 0.308);
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
    margin-bottom: 3px;
    background: var(--green);
} */

/* .service_drop a:hover {
    background-color: var(--orange); 
    color: white;
    border-radius: 10px;
} */

/* .profile_section{
    border: 5px solid red;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
} */

.profile_section{
    /* border: 5px solid red; */

    width: 100%;
    height: 94%;
    padding-right: 0.5rem;
    margin-top: 3%;
    /* margin-top: 1%; */
}


.coming_div{
    /* border: 5px solid pink;  */

    display: flex;
    width: 100%;
    height: 100%;
    gap: 10px;

    /* display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%; */
}

.grid_profile_one{
    /* border: 5px solid purple; */
    width: 35%;
}

.grid_profile_two{
    /* border: 5px solid purple; */
}

@media(max-width:320px){
    .profile_bar {
        padding: 0.3rem 0.3rem;
    } 

    .welcome-greet {
        font-size: 0.7rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        margin-bottom: 0;
    }

    .logoDiv{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .partner_welcome{
        font-size: 0.8rem;
        font-weight: 500;   
    }

    .profile_logOut {
        padding: 0.1rem 0.3rem;
        font-size: 0.7rem;
        font-weight: 300;
    }

    .profile_account_section {
        /* border: 5px solid green; */
        margin-top: 11%;
    }

    .categories_div {
        margin: -2% 0px;
        width: 100%;
        background: white;
        padding: 0.3rem 0rem;
    }

    .profile_category {
        font-size: 0.7rem;
        font-weight: 300;
        padding: 0.1rem 0.2rem;
        width: fit-content;
    }
}

@media (min-width:321px) and (max-width: 375px){
    .profile_wrapper{
        height: 100vh;
        overflow-x: hidden;
        padding-bottom: 0px;
    }

    .profile_profile_bar {
        position: relative;
        width: 100%;
    }

    .homeLinkUser {
        display: none;
    }

    .logoDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .welcome-greet {
        font-size: 0.8rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        margin-bottom: 0;
    }

    .partner_welcome {
        font-size: 0.9rem;
        font-weight: 500;
    }

    .profile_logOut {
        padding: 0.1rem 0.3rem;
        font-size: 0.8rem;
        font-weight: 300;
    }

    .profile_bar {
        padding: 0.4rem 0.3rem;
    }
    
    .profile_account_section {
        margin-top: 9.5%;
    }

    .profile_content {
        position: relative;
        width: 100%;
        padding: 0rem 0.2rem;
    }

    .categories_div {
        margin: -1% 0px;
        width: 100%;
        background: white;
        padding: 0.5rem 0rem;
    }

    .profile_category {
        font-size: 0.8rem;
        font-weight: 300;
        padding: 0.1rem 0.2rem;
        width: -moz-fit-content;
        width: fit-content;
    }

    .profile_section {
        height: 98%;
        padding-right: 0rem;
    }
} 

@media (min-width:376px) and (max-width: 425px){
    .profile_wrapper{
        height: 100vh;
        overflow-x: hidden;
        padding-bottom: 0px;
    }

    .profile_profile_bar {
        position: relative;
        width: 100%;
    }

    .homeLinkUser {
        display: none;
    }

    .logoDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .welcome-greet {
        font-size: 0.8rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        margin-bottom: 0;
    }

    .partner_welcome {
        font-size: 0.9rem;
        font-weight: 500;
    }

    .profile_logOut {
        padding: 0.1rem 0.3rem;
        font-size: 0.8rem;
        font-weight: 300;
    }

    .profile_bar {
        padding: 0.4rem 0.3rem;
    }
    
    .profile_account_section {
        margin-top: 9.5%;
    }

    .profile_content {
        position: relative;
        width: 100%;
        padding: 0rem 0.2rem;
    }

    .categories_div {
        margin: -1% 0px;
        width: 100%;
        background: white;
        padding: 0.5rem 0rem;
    }

    .profile_category {
        font-size: 0.8rem;
        font-weight: 300;
        padding: 0.1rem 0.2rem;
        width: -moz-fit-content;
        width: fit-content;
    }

    .profile_section {
        height: 98%;
        padding-right: 0rem;
        margin-top: 8%;
    }
} 


@media (min-width: 768px) and (max-width:1023px) {
    .profile_account_section{
        margin-top: 8%;
    }

    .categories_div {
        margin-top: -0.6%;
    }

    .profile_category {
        font-size: 15px;
    }

    .profile_section {
        margin-top: 4%;
    }

    .coming_div {
        flex-direction: column;
    }

    .grid_profile_one {
        width: 100%;
    }
}