@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --green:rgb(30, 165, 9);
  --orange: rgb(255,165,0);
}

#specialOrderModal {
  background-color: rgb(0, 0, 0, 0.7);
  width: 100%;
  margin-top: 5%;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.special-modal-dialog{
  width: 50%;
  margin-top: 2%;
  margin-left: 25%;
}

.modalContent{
  width: 100%;
  background: white;
  padding: 10px 5px;
}

.modalHeader{
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  color: var(--green);
  width: 100%;
  padding: 10px 5px;
  margin-bottom: 1%;
}

.modalTitle{
  font-size: 20px;
  font-weight: 800;
  text-decoration: underline;
}

.specialClose {
  padding: 5px 10px;
  border: none;
  color: white;
  background: var(--green);

}

.specialClose:hover {
  color: var(--orange);
  background: white;
  outline: 1px solid var(--orange);
  transition: ease-in 0.2s;
  text-decoration: none;
}

.specialCloseIcon{
  font-size: 18px;
}

.modalBody{
  width: 100%;
  padding: 0px;
}

#specialOrderForm{
  width: 100%;
  padding-top: 5px;
}

.specialTriple{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 10px;
  justify-content: space-around;
  width: 100%;
}

.specialdouble{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 10px;
  justify-content: space-around;
  width: 100%;
}

.special_times{
  padding: 5px;
}

.formGroup {
  margin-bottom: 15px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.deliveryDiv{
  margin-bottom: 5%;
}

.specialLabels{
  text-align: left;
  margin-bottom: 2px;
  font-size: 17px;
}

.specialPin{
  background: var(--green);
  color: white;
  font-size: 15px;
  padding: 5px;
  border: none;
  outline: none;
  margin-bottom: 2px;
}

.specialPin:hover,.specialPin:focus{
  background: white;
  color: var(--orange);
  outline: 1px solid var(--orange);
}

.locationBtns{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.formControl {
  font-size: 16px;
  border-radius: 0px;
  border: none;
  outline: 1px solid rgba(128, 128, 128, 0.203);
}

.formControl:hover, .formControl:focus{
  color: var(--orange);
  outline: 1px solid var(--orange);
  cursor: pointer;
}

.specialTripleInputs{
  padding: 5px;
}

#btnPrimary {
  background: var(--green);
  border: none;
  padding: 5px 20px;
  font-size: 16px;
  color: white;
  transition: ease-in .2s;
}

#btnPrimary:hover {
  background: white;
  color: var(--orange);
  outline: 1px solid var(--orange);
}

.specilLocation{
  padding: 5px;
}

#deliveryLocation{
  padding: 5px;
  margin-bottom: 10px;
}

#map {
  height: 150px;
  width: 100%;
  margin-top: 10px;
  display: none;
}

@media(min-width : 321px) and (max-width: 375px){
  #specialOrderModal {
    margin-top: 10%;
    width: 97%;
  }

  .specialModalContent{
    width: 355px;
    margin-left: -5.5rem;
  }

  .modalHeader{
    padding: 0.3rem;
  }

  #specialOrderModalLabel{
    font-size: 1rem;
  }

  .formControl{
    font-size: 0.8rem;
    padding: 0.1rem;
    margin-bottom: 0rem;
  }

  .specialPin{
    font-size: 0.8rem;
    padding: 0.2rem 0.1rem;
  }

  .specialLabels{
    font-size: 0.8rem;
  }

  .specialTriple{
    grid-template-columns:1fr;
    gap: 5px;
  }

  #specialOrderArea{
    height: 7rem;
  }

  #btnPrimary{
    font-size: 0.8rem;
  }
}

@media(min-width : 376px) and (max-width:425px){
  #specialOrderModal {
    margin-top: 10%;
    width: 100%;
    margin-left: -3%;
  }

  .specialModalContent{
    width: 400px;
    margin-left: -5.5rem;
  }

  .modalHeader{
    padding: 0.3rem;
  }

  #specialOrderModalLabel{
    font-size: 1rem;
  }

  .formControl{
    font-size: 0.8rem;
    padding: 0.1rem;
    margin-bottom: 0rem;
  }

  .specialPin{
    font-size: 0.8rem;
    padding: 0.2rem 0.1rem;
  }

  .specialLabels{
    font-size: 0.8rem;
  }

  .specialTriple{
    grid-template-columns:1fr;
    gap: 5px;
  }

  #specialOrderArea{
    height: 7rem;
  }

  #btnPrimary{
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) and (max-width:1024px) {
  .specialModalContent{
    width: 700px;
    margin-left: -10rem;
  }

  .specialTriple{
    grid-template-columns:1fr;
    gap: 5px;
  }

  #specialOrderArea{
    height: 10rem;
  }

  #map {
    height: 200px;
    width: 100%;
    margin-top: 10px;
    display: none;
  }

  #specialOrderModal {
    margin-top: 6%;
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .special-modal-dialog {
    width: 60em;
    margin-left: 10rem;
    margin-top: 1rem;
  }

  #specialOrderArea{
    height: 150px;
  }
}