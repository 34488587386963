@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

#orderSummaryModalLabel{
    font-size: 1.3rem;
    font-weight: 800;
    font-style: normal;
    color: var(--green);
    text-decoration: underline;
}

.form-group{
    /* padding-bottom: 20px; */
}

.summary{
    font-weight: 500;
    font-style: normal;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

.closeOrder{
    background-color: var(--green);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    transition: ease-in .2s;
}

.closeOrder:hover{
    background-color: white;
    color: var(--orange);
    outline: 1px solid var( --orange);
}

.text-muted{
    font-size: 1rem;
}

.summary_total_details{
    font-size: 0.9rem;
}

.price{
    color: var(--orange);
    font-weight: 800;
    font-size: 16px;
}

.optionPay{
    background: var(--green);
    color: white;
    padding: 0.4rem 0.5rem;
    margin-right: 0.3rem;
    border: none;
    transition: ease-in .2s;
}

.optionPay:hover,.optionPay:focus{
    background: white;
    color: var(--orange);
    outline: 1px solid var(--orange);
}


@media (max-width:320px){
    #sumaryModalcontent{
        left: -3%;
        margin-top: 2%;
    }
}

@media (min-width: 1024px) and (max-width: 1440px){
    #sumaryModalcontent{
        left: -3%;
        margin-top: -6%;
    }
}