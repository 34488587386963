@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
    --transition-short: all .2s ease-in-out;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
}

.footer-section {
  overflow: hidden;
  background: #151414;
  position: relative;
  width: 100%;
}

.top_header_div{
    display: grid;
    grid-template-columns: repeat(3 ,1fr);
    border-bottom: 1px solid #373636;
    margin-bottom: 2%;
    padding-bottom: 1%;
}

.top_header_divs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#top_header_icons {
  color: var(--orange);
  font-size: 30px;
  margin-top: 8px;
  margin-bottom: 4%;
  margin-top: 5%;
}

.top_header_divs_headers{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
}

.top_header_divs_para{
    font-size: 15px;
    color: var(--orange);
}

.footer-content {
    position: relative;
    padding: 0px 5%; 
    overflow-x: hidden;
    /* background-image: url(../../assets/footerBackground.jpg); */
    background-repeat: none;
    background-position: center;
    background-size: contain;
}

.row_two{
    display: grid;
    grid-template-columns: 1.2fr 0.5fr 1fr;
    gap: 0.5%;
    padding-bottom: 1rem;
}

.column_footer{
    height: 100%;
    padding-top: 5%;
    z-index: 999;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer_logo_link{
    text-decoration: none;
}

.footer_logo_head{
    color: var(--orange);
    font-size: 5em;
}

.footer-logo img {
    max-width: 200px;
}

.footer-logo-text {
    margin-bottom: 14px;
    font-size: 17px;
    color: white;
    line-height: 28px;
    text-align: left;
}

.privacy_list{
    list-style: none;
    position: relative;
}

.privacy_link{
    color: var(--green);
    text-align: left;
    transition: ease-in .2s;
}

.privacy_link:hover{
    color: var(--orange);
    z-index: 3;
    font-size: 18px;
}

.footer-social-icon {
    border: 2px solid var(--green);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.social_header {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  border-bottom: 4px solid var(--orange);
}

.social_icon{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;

}

.social_link {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#social_list {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  background: var(--orange);
  transition: ease-in .2s;
  font-size: 25px;
}

#social_list:hover {
  background: white;
  cursor: pointer;
  color: var(--orange);
}

.column_contactus{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer_contactUs_heading{
    color: white;
    border-bottom: 4px solid var(--orange);
    width: fit-content;
    margin-bottom: 15px;
}

.contact_form {
    width: 100%;
    height: 90%;
    color: #fff;
    overflow-y: hidden;
}

.your_email{
    width: 100%;
    margin-bottom: 2px;
    border: none;
    outline: none;
    padding: 5px 10px;
}

.contact_text_email{
    padding: 5px 10px;
    width: 100%;
    height: 100%;
    border: none;
    overflow-y: hidden;
}

.contact_text_email:hover,.contact_text_email:focus{
    cursor: pointer;
    border: none;
    color: var(--orange);
    outline: 1px solid var(--orange);
}

.contact_form_btn {
    background: var(--green);
    padding: 5px 10px;
    border: 1px solid var(--orange);
    width: 100%;
    color: white;
    border: none;
    transition: ease-in .2s;
    animation: wiggle 2s linear infinite;
}

@keyframes wiggle {
    0%, 7% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(-15deg);
    }
    20% {
        transform: rotateZ(10deg);
    }
    25% {
        transform: rotateZ(-10deg);
    }
    30% {
        transform: rotateZ(6deg);
    }
    35% {
        transform: rotateZ(-4deg);
    }
    40%, 100% {
        transform: rotateZ(0);
    }
}

.contact_form_btn:hover {
    background: white;
    cursor: pointer;
    color: var(--orange);
    outline: 1px solid var(--orange);
    animation:none;
}

.copyright_div{
  /* border: 5px solid red; */

  background: var(--orange);
  padding: 1rem 0px;
  text-align: center;
}

.copyright_text {
  /* border: 5px solid red; */

  font-size: 1rem;
  color: white;
}

.car {
    background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEia0PYPxwT5ifToyP3SNZeQWfJEWrUENYA5IXM6sN5vLwAKvaJS1pQVu8mOFFUa_ET4JuHNTFAxKURFerJYHDUWXLXl1vDofYXuij45JZelYOjEFoCOn7E6Vxu0fwV7ACPzArcno1rYuVxGB7JY6G7__e4_KZW4lTYIaHSLVaVLzklZBLZnQw047oq5-Q/s16000/volks.gif") no-repeat center center;
    width: 15%;
    height: 26%;
    background-size:100%;
    position: absolute;
    bottom: 15%;
    left: 30%;
    -webkit-animation: myfirst 22s linear infinite;
    animation: myfirst 22s linear infinite;
    z-index: 1;
}

.bike {
    background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhyLGwEUVwPK6Vi8xXMymsc-ZXVwLWyXhogZxbcXQYSY55REw_0D4VTQnsVzCrL7nsyjd0P7RVOI5NKJbQ75koZIalD8mqbMquP20fL3DxsWngKkOLOzoOf9sMuxlbyfkIBTsDw5WFUj-YJiI50yzgVjF8cZPHhEjkOP_PRTQXDHEq8AyWpBiJdN9SfQA/s16000/cyclist.gif") no-repeat center center;
    width: 88px;
    height: 100px;
    background-size:100%;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 30s linear infinite;
    animation: myfirst 30s linear infinite;
    z-index: 2;
}

@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .footer-content {
    padding: 0px 2%;
  }

  .row_two {
    display: grid;
    grid-template-columns: 1.1fr 0.5fr 1fr;
    gap: 0.5%;
    padding-bottom: 1rem;
  }

  .contact_form {
    padding: 0px;
  }
}

/* MEDIA QUERIES */
@media (max-width: 1024px) and (max-width: 768px) {
  .footer_logo_head {
    font-size: 3em; 
  }

  .row_two {
    grid-template-columns: 1fr; 
    gap: 15px;
  }

  .footer-logo-text {
    font-size: 13px;
  }

  .contact_form_btn {
    font-size: 12px;
    padding: 8px; 
  }

  #social_list {
    font-size: 20px;
    width: 35px;
    height: 35px;
  }

  .car, .bike {
    width: 10%;  
    height: 10%;
  }

  .car {
    bottom: 3%;
  }
}


/* Very small devices (small mobile phones) */
@media only screen and (max-width: 480px) {
  .top_header_div{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding-bottom: 0rem;
    justify-content: space-around;
  }

  .top_header_divs{
    width: 8rem;
  }

  #top_header_icons{
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 1.2rem;
  }

  .top_header_divs_headers{
    font-size: 0.8rem;
  }

  .top_header_divs_para{
    font-size: 0.6rem;
    width: 95%;
    text-align: center;
  }
  
  .footer-content{
    padding: 0px 0px;
    overflow-y: hidden;
    height: fit-content;
  }

  .row_two {
    margin: 0;
  }

  .column_footer{
    padding: 0;
  }

  .footer-widget{
    width: 100%;
    padding: 0.5rem;
  }

  .footer-logo{
    margin-bottom: 1rem;
  }

  .footer_logo_head {
    font-size: 1.5rem; 
  }

  .footer-logo-text {
    font-size: 0.9rem;
    text-align: left;
  }

  .privacy_link{
    font-size: 0.8rem;
  }

  .footer-social-icon {
    border: none;
  }

  .social_header{
    margin-bottom: 1.5rem;
    font-size: 1rem;
    border-bottom: 0.2rem solid var(--orange);
  }

  .social_icon{
    grid-template-columns: repeat(5,1fr);
    gap: 2rem;
  }

  .column_contactus{
    width: 100%;
    height: 80%;
    max-width: 100%;
    margin-top: 1.5rem;
  }

  .contact_form{
    padding: 0rem 0.1rem;
    height: 18rem;
  }

  .your_email,.contact_text_email{
    text-align: center;
  }

  .your_email:focus{
    border: none;
  }

  .copyright_div{
    margin-top: 0.5rem;
    height: 3rem;
  }

  .car {
    bottom: 5%;
    left: 0%;
    width: 28%;
    height: 6%;
}

  .bike {
      bottom: 1;
      left: 38%;
      width: 4rem;
      height: 8rem;
  }

  .copyright_text{
    font-size: 0.8rem;
  }

  .footer_logo_head {
    font-size: 2em; 
  }

  .row_two {
    grid-template-columns: 1fr; 
    gap: 15px;
  }

  .footer-logo-text {
    font-size: 13px;
  }

  .contact_form_btn {
    font-size: 12px;
    padding: 8px; 
  }

  #social_list {
    font-size: 20px;
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .top_header_div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .column_footer {
    padding-top: 2%;
  }

  .footer-content {
    padding: 0px 2%;
  }

  .footer-logo-text {
    text-align: left;
  }

  .footer-social-icon {
    border:none;
  }

  .social_icon {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 1.5rem;
  }

  #social_list {
    font-size: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .footer_contactUs_heading {
    font-size: 1.2rem;
  }

  .contact_form {
   padding: 0.1rem;
  }

  .your_email {
    padding: 0.3rem 0.4rem;
    font-size: 1rem;
  }

  .your_email:focus {
    padding: 0.3rem 0.4rem;
    font-size: 1rem;
  }

  .contact_text_email {
    width: 100%;
    height:15rem;
    border: none;
  }
}


@media (max-width: 320px){
  .top_header_divs {
    width: 7rem;
  }
}