@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
  --green:rgb(30, 165, 9);
  --orange: rgb(255,165,0);
}


.modal {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.662);
  display: flex;
  justify-content: center;
  align-items: center;
}


.modalDiv {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 700px;
  /* margin-top: 15%; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}

.trackingHeader{
  /* border: 1px solid blue; */

  text-align: center;
  font-weight: 700;
  font-size: 25px;
  font-style: normal;
  color: var(--green);
  margin-bottom: 30px;
  text-decoration: underline;
}

.input_button{
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

/* not picking the styles */
.trackingInput{
  width: 60%;
  padding: 5px 10px;
  border: 1px solid rgba(128, 128, 128, 0.27);
}

.trackingInput:hover{
  border: 1px solid var(--orange);
  cursor: pointer;
}

/* .trackingInput:focus{
  border: none;
} */

/* not picking the styles */
.trackingButton{
  border: none;
  padding: 5px 10px;
  border: 1px solid rgba(128, 128, 128, 0.27);
  background: var(--green);
  color: white;
}

.trackingButton:hover{
  background: white;
  color: var(--orange);
  border: 1px solid var(--orange);
}

#orderSchematic{
  /* border: 1px solid saddlebrown; */
}
  
.step {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
}
  
.step.completed {
  background: var(--orange);
  color: white;
}

#orderDetails2{
  /* border: 1px solid yellowgreen; */
}

#orderDetails2 {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 10px;
  gap: 10px;
}

.details{
  border: 1px solid rgba(128, 128, 128, 0.221);
  padding: 10px 10px;
  border-left: none;
  border-right: none;
}

.tagSpan{
  font-weight: 600;
}

.divDetail_one{
  font-weight: 300;
}

.divDetail_two{
  font-weight: 300;
}

.detailP{
  /* border: 1px solid pink; */
  margin: 10px 0px;
}

.orderUnList{
  padding-left: 0;
}

.orderList{
  list-style: none;
}

.closeBtnDiv{
  width: 100%;
  text-align: right;
  margin-top: 10px;
}

.trackinCloseButton{
  /* border: 1px solid purple; */
  padding: 5px 10px;
  border-radius: 5px;
  background: var(--green);
  color: white;
  transition: linear 0.2s;
  border: none;
}

.trackinCloseButton:hover{
 background: white;
 color: var(--orange);
 outline: 1px solid  var(--orange);
 cursor: pointer;
}