@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --green:rgb(30, 165, 9);
  --orange: rgb(255,165,0);
}

#dishModalLabel{
  font-weight: 800;
  text-decoration: underline;
  color: var(--green);
}

.restaurant-section {
  /* border: 1px solid red; */

  margin-bottom: 1rem;
  border-bottom: 1px solid black;
  padding-bottom: 0.5rem;
}

.restaurant-heading {
  /* border: 1px solid red; */

  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 0.5rem;
}

.dishes-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
  row-gap: 10px;
}

@media (max-width:320px){
  #dishModalBody{
    width: 100vw;
    left: -2.5%;
  }

  #dishesModalContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1px;
    row-gap: 5px;
  }

  #dishModalLabel {
    font-size: 14px;
  }

  .restaurant-heading{
    font-size: 14px;
  }

  #categoryModalBody{
    padding: 1.5px;
  }
}

@media (min-width: 376px) and  (max-width:425px){
  .restaurant-heading{
    font-size: 1rem;
  }

  .dishes-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 10px;
  }
}

@media (min-width: 426px) and (max-width: 768px){
  .restaurant-heading {
    font-size: 1rem;
  }

  .dishes-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 769px) and (max-width: 1024px){
  .dishes-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    row-gap: 10px;
  }
}

@media (min-width: 1025px) and (max-width: 1440px){
  .dishes-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    row-gap: 10px;
  }

  .restaurant-heading {
    font-size: 1rem;
  }

  #dishModalBody {
    width: 900px;
    top: -2rem;
  }
}
  