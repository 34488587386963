@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

.freshfoodDalog {
    /* overflow-y: hidden; */
    margin-top: 3%;
    /* z-index: 10000; */
}


.valueControllers{
    color: white;
    background: var(--green);
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    transition: ease-in .2;
}

.valueControllers:hover{
    color: var(--orange);
    background: white;
    outline: 1px solid var(--orange);
}

@media (min-width: 321px) and (max-width: 375px){
    .valueControllers {
        padding: 0.3rem 0.5rem;
        margin-right: 4px;
    }

    .me-2 {
        margin-right: .3rem !important;
    }

    .ms-2 {
        margin-left: .3rem !important;
    }
}

@media (min-width: 376px) and (max-width: 425px){
    .me-2 {
        margin-right: .3rem !important;
    }

    .menuDishQuantity {
        width: 0.5rem;
        font-size: 0.8rem;
    }

    .ms-2 {
        margin-left: .3rem !important;
    }

    .ms-2 {
        margin-left: .3rem !important;
    }

    .valueControllers {
        padding: 0.1rem 0.5rem;
        margin-right: 4px;
    }
}

@media (min-width: 768px) and (max-width:1024px) {
    .foodCartlocationContent{
        width: 140%;
        margin-left: -20%;
        height: 600px;
    }
}


@media (min-width: 1025px) and (max-width: 1440px) {
    .freshfoodDalog {
        margin-top: 2%;
    }

    #food_location_modal{
        height: 400px;
    }

    #mapContainer{
        height: 310px;
    }
}
