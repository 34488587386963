:root{
  --green:#1ea509;
  --orange: rgb(255,165,0);
}

.searchBar {
  padding: 3px;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search__input{
  border: 1px solid greenyellow;
}

.stop{
  border: 1px solid greenyellow;
}

#searchResults {
  /* border: 1px solid red; */

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: white;
  color: black;
  position: absolute;
  z-index: 80;
  top: 32%;
  /* left: 23%; */
  left: 26%;
  /* width: 50%; */
  width: 45%;
}

.suggestion-item {
  cursor: pointer;
  padding: 10px 20px;
  background-color: var(--green);
  border-bottom: 1px solid white;
  color: white;
  display: grid;
  grid-template-columns: repeat(3 ,1fr);
  gap: 100px;
  transition: ease-in .2s;
}

.landDishSearch{
  cursor: pointer;
  color: white;
  font-size: 15px;
}

.suggestion-item:hover {
  background-color: white;
  color: var(--orange);
}

.suggestion-item:hover span {
  color: var(--orange);
}

#details{
  width: 70%;
}

.detail-container {
  background-color: var(--orange);
  background-size: contain;
  text-align: center;
  color: rgb(3, 2, 17);
  display: grid;
  grid-template-columns: 1fr 0.6fr;
}

.result_display{
  /* border: 1px solid black; */
  padding: 5px 10px;
}

.img-result_display{
  object-fit: contain;
}

.detail-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.result-result_display{
  color: white;
}
  
.mic-icon {
  cursor: pointer;
  color: var(--green);
  padding: 10px;
  margin-right: 10px;
  font-size: 20px;
  transition: ease-in .2s;
}

/* .search-tool-icon {
  cursor: pointer;
  color: #0a0af1;
  padding: 10px;
  margin-left: 10px;
} */
  
.mic-icon:hover
/* .search-tool-icon:hover  */
{
  color: var(--orange); 
  font-size: 30px;
  margin-right: 0px;
}
  
.show + .mic-icon i,
.hide + .mic-icon i,
#stop {
  color: #ffffff;
  background-color: #eb2106ff;
  padding: 5px;
  border-radius: 8px;
  margin-right: 10px;
}
.show {
  display: block;
}
.hide {
  display: none;
}

/* MEDIA QUERIES */
/* Large desktops and laptops */
@media (min-width: 1200px) {
  #searchResults {
    
  }

  .suggestion-item {
    grid-template-columns: repeat(3, 1fr);
    gap: 80px;
  }

  .detail-container {
    grid-template-columns: 1fr 0.8fr;
  }

  .mic-icon {
    font-size: 25px;
    margin-right: 15px;
  }

  .mic-icon:hover {
    font-size: 35px;
  }
}


@media (max-width: 320px){
  .search__container{
    padding-top: 0.7rem;
    width: 95vw;
    height: 3rem;
    margin-top: 1rem;
  }
}

@media (min-width: 321px) and (max-width: 375px){
  .searchBar {
    margin-top: 1%;
    margin-bottom: 1%;
    width: 100%;
  }

  .search__container{
    padding-top: 0.7rem;
    width: 95vw;
    height: 3rem;
    margin-top: 1rem;
  }

  .search__input{
    padding: 0.3rem 0.5rem;
    text-align: center;
    width: 450px;
  }
}

@media (max-width: 480px) and (min-width:376px) {
  .searchBar {
    /* border: 1px solid blue; */

    margin-top: 5%;
    width: 100%;
  }

  #searchResults {
    top: 15%;
    left: 2%;
    width: 86%;
  }

  .suggestion-item {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0.2rem;
  }

  .search__container{
    /* border: 1px solid blue; */

    padding-top: 0.7rem;
    width: 95%;
    height: 4rem;
    margin-bottom: 0.5rem;
  }

  .search__input{
    padding: 0.5rem 1rem;
    text-align: center;
    width: 400px;
  }

.search__input:hover,
.search__input:focus {
    padding: 0.7rem 0.9rem;
    /* outline: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--green);
    border-radius: 0;
    background-position: 97% center; */
}


  .landDishSearch{
    /* border: 1px solid blue; */

    font-size: 0.6rem;
  }

  .detail-container {
    grid-template-columns: 1fr;
  }

  .mic-icon {
    font-size: 18px;
    margin-right: 8px;
  }

  .mic-icon:hover {
    font-size: 25px;
  }
}


/* Tablets (portrait and landscape) */
@media (max-width: 1024px) and (min-width: 769px) {
  #searchResults {
    top: 19%;
    left: 16%;
    width: 65%;
  }

  .suggestion-item {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    padding: 10px 15px;
  }

  .landDishSearch {
    font-size: 1rem;
  }

  .detail-container {
    grid-template-columns: 1fr;
  }

  .mic-icon {
    font-size: 1.3rem;
    margin-right: 0px;
  }

  .mic-icon:hover {
    font-size: 30px;
  }
}