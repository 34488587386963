
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

*{
    font-family: "poppins", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.containerDiv{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.headerSection{
    height: 20%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3%;
    border-bottom: 1px solid rgba(128, 128, 128, 0.577);
}

.logo-CTA{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    width: 100%;
    z-index: 100;
    background: var(--orange);
    top: 0;
}


.land_logo{
    color: white;
}

  

.landing_sign{
    padding: 7px 25px;
    font-weight: 700;
    font-size: 17px;
    font-style: normal;
    border: none;
    margin-right: 1cm;
    background: var(--green);
    color: white;
}

.services-serchBar{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
}

.search__container {
    padding-top: 20px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.search__input {
    width: 700px;
    padding: 12px 24px;
    background-color: transparent;
    transition: transform 250ms ease-in-out;
    font-size: 14px;
    line-height: 18px;
    color: #575756;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 95% center;
    border-radius: 50px;
    border: none;
    transition: all 250ms ease-in-out;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.search__input::placeholder {
    color: rgba(87, 87, 86, 0.8); 
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.search__input:hover::placeholder {
    color: var(--green); 
}

.search__input:hover,
.search__input:focus {
    padding: 12px 15px;
    outline: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--green);
    border-radius: 0;
    background-position: 97% center;
}

.services_slogan{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.slogan{
    margin-bottom: 20px;
}

.sloganParagraph{
    font-weight: 200;
    font-size: 40px;
    font-style: normal;
}

.services{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    margin-bottom: 30px;
}

.serviceDiv{
    position: relative;
    border: 1px solid black;
    height: 220px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 20px 30px;
    border: none;
    overflow: hidden;
    object-fit: cover;
}

#moreServices:disabled{
    background: grey;
}

.serviceImg{
    position: absolute;
    transition: transform 0.3s ease;
    width: 150px; 
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 5px;
    background: var(--orange);
    top: 18px;
}

.serviceImg:hover{
    cursor: pointer;
}

.faIcons{
    color: var(--orange);
}

#disabled{
    pointer-events: none; 
    opacity: 0.5;       
    cursor: not-allowed; 
    transition: ease-in .2s;
}

.serviceDiv p{
    font-weight: 700;
    font-size: 17px;
    font-style: normal;
    position: absolute;
    bottom: 0px; 
    left: 50%; 
    transform: translateX(-50%); 
    width: 200px;
    color: var(--orange);
}

.serviceDiv p:hover{
    cursor: pointer;
}

.serviceDiv:hover .serviceImg {
    transform: scale(1.2); 
}

.serviceDiv:hover p {
    color: var(--orange);
}

.offersSection{
    width: 90%;
    padding: 2px 20px;
    height: fit-content; 
    margin-bottom: 3%;
}

.title-offerSearch{
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
}

.search-container{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.8s;
    position: relative;
    width: 30em;
}

.search-input{
    width: 400px;
    border: none;

    outline: 1px solid var(--green);
    background: #fff;
    height: 30px;
    padding: 0.5rem;
    padding: 10px 20px;
    transition: ease-in 0.2s;
}

.search-input:hover,.search-input:focus{
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
}

.search-btn{
    width: 2rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--green);
    border: none;
}

.search-btn:hover{
    cursor: pointer;
    background: white;
    color: orange;
    outline: 1px solid var(--orange);
}

.search-results{
    z-index: 100;
    position: absolute;
    top: 100%;
    width: 100%;
}

.search-result-item{

    background: var(--green);
    color: white;
    padding: 0.5rem ;
    border-bottom: 1px solid white;
    width: 100%;
}

.search-result-item:hover{
    background: white;
}

.search-result-item:hover .search-result-item-a{
    color: var(--orange);
}

.search-result-item-a{
    width: 100%;
    color: white;
}

.search-result-item-p{
    border: 1px solid red;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.search-result-item-p:hover{
    background: white;
    color: var(--orange);
    border-bottom: 1px solid white;
}

.offerNavContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
}

.offerTitle{
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
    text-decoration: underline;
}

.offers-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.offerDisplay{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 30px;
    margin-top: 20px;
}

.food-cards-container {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    justify-content: center;
    column-gap: 30px;
}

.categories{
    padding: 5px 10px;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    border: none;
    outline: 1px solid rgba(128, 128, 128, 0.519);
    background: var(--green);
    color: white;
}

.categories:hover{
    cursor: pointer;
    background: var(--orange);
    color: white;
    outline: 1px solid var(--green);
    font-size: 15px;
}

.allBtn{
    margin-right: 6px;
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    z-index: 1;
    left: 134px;
}

.dropdown-content a {
    border: 1px solid rgba(0, 0, 0, 0.308);
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
    margin-bottom: 3px;
}

.dropdown-content a:hover {
    background-color: var(--orange); 
    color: white;
    border-radius: 10px;
    border: 1px solid var(--green);
}

.offerDispaly{
    margin-top: 3%;
    margin-bottom: 20px;
}

.featuredSection{
    min-height: 100vh;
    width: 90%;
    padding: 5px 10px;
    margin-bottom: 2%;
    border-top: 1px solid rgba(128, 128, 128, 0.577);
}

.divHotel{
    margin-bottom: 3%;
}

.FeaturedDiv{
    margin-bottom: 3%;
}

.FeaturedHeading{
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
    text-decoration: underline;
    margin-bottom: 1%;
}

.DishCards{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-content: center;
    flex-direction: row;
}

.featured{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: 10px;
}

.featuredHotel{
    margin-bottom: 10px;
}

.Hotel{
    padding: 5px;
}

.Food{
    padding: 5px; 
}

.join_team_section{
    width: 95%;
    height: 90vh;
    padding: 10px 5px;
}

.join_team_div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.paragraph_div{
    margin-bottom: 1%;
    width: 100%;
    text-align: center;
}

.join_team_heading{
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
}

.join_team_grid_div{
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3,1fr);
    gap: 40px;
    width: 85%;
}

.join_team_choices{
    border: 1px solid var(--orange);
    background: var(--orange);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.join_title{
    padding:  10px;
    margin-bottom: 5px;
    text-align: center;
    width: 80%;
    font-size: 23px;
    font-weight: 700;
    color: white;
    text-decoration: underline;
}

.join_explanation{
    width: 80%;
    text-align: center;
    margin-bottom: 5px;
    font-size: 18px;
}

.join_team_image_div{
    width: 300px;
    height: 300px;
    object-fit: contain;
    margin-bottom: 20px;
}

.join_img{
    width: 295px;
    height: 295px;
}

.signup{
    border: none;
    padding: 10px 20px;
    color: white;
    background: var(--green);
    font-size: 18px;
    margin-bottom: 5px;
    width: 9rem;
    transition: 0.2s ease-in;
}

.signup:hover{
    cursor: pointer;
    background: white;
    outline: 1px solid var(--orange);
    color: var(--orange);
}

.about_us_section{
    width: 90%;
    height: 70vh;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about_us_div{
    padding: 5px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.about_us_title{
    padding:  1px;
    text-align: center;
    text-decoration: underline;
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
}

.aboutUs_img_div{
    object-fit: contain;
    border-radius: 50%;
    shape-outside: circle();
    margin: 20px;
}

.aboutUs_img_div1{
    float: left;
    width: 350px;
    height: 350px;
}

.aboutUs_img_div2{
    float: right;
    width: 250px;
    height: 300px;
}

.about_us_img{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.aboutUs_paragraph{
    text-align: justify;
    font-size: 18px;
    font-weight: 350;
} 

/* MEDIA QUERIES */
@media (max-width: 320px){
    .containerDiv {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 0px;
        overflow: hidden;
    }

    .logo-CTA {
        padding: 0.5rem 0.3rem;
    }

    .land_logo {
        margin-bottom: 0;
        font-size: 1.2rem;
    }

    .landing_sign {
        padding: 0.3rem 0.5rem; 
        font-size: 0.8rem; 
        margin-right: 0;
    }

    .search__input {


        width: 100%;
        padding: 0.3rem 0.3rem;
        font-size: 0.7rem;
        line-height: 18px;
        text-align: center;
    }

    .services_slogan{
        margin-top: 0;
    }
    
    .slogan{
        margin-bottom: 0;
    }

    .sloganParagraph{
        font-size: 1rem;
        margin-bottom: 0;
        font-weight: 200;
    }

    .services{
        grid-template-columns: repeat(3 , 1fr);
        gap: 0px;
        margin-bottom: 0;
        row-gap: 0;
    }

    .serviceDiv{
        width: 7rem;
        height: 9rem;
        padding: 0;
    }

    .serviceImg{
        width: 5.5rem;
        height: 5.5rem;
        margin-bottom: 0px;
    }

    .more_icon{
        font-size: 6em;
        margin-top: -1.2rem;
    }

    .serviceDiv p{
        font-size: 0.7rem;
        bottom: 1rem;
        margin-bottom: 0;
        width: 8rem;
    }

    #longConference{
        margin-bottom: -1rem;
        width: 7rem;
    }

    .offersSection{
        width: 100%;
        padding: 0;
        padding-top: 0rem;
    }

    .title-offerSearch{
        margin-bottom: 0;
        padding: 0;
        padding-left: 0.2rem;
        text-align: left;
    }

    .offerTitle{
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    .category-dispaly{
        width: 100%;
    }

    .offerNavContainer{
        padding: 0rem 0.2rem;
    }

    .allBtn{
        display: none;
    }

    .categories {
        padding: 3px 6px;
        font-size: 10px;
    }

    .categories:focus{
        font-size: 0.8rem;
        padding: 0.2rem 0.5rem;
    }

    .search-container{
        width: 11em;
    }

    .search-input{
        padding-top: 0.2rem 0rem;
        padding-left: 0.2rem;
        width: 80%;
        font-size: 0.7rem;
        outline: none;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        flex: none;
    }

    .search-btn{
        padding: 0.4rem;
    }

    .offers-container{
        margin-top: 0.5rem;
    }

    .offerDispaly{
        margin-top: 0%;
    }

    .offerDisplay{
        margin-top: 0.5rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 0.3rem;
        row-gap: 0.3rem;
        padding: 0rem 0.2rem;
    }

    .featuredSection{
        width: 100%;
        margin-bottom: 2%;
    }

    .featuredSection{
        padding: 0px;
    }

    .divHotel{
        width: 100%;
    }

    .FeaturedDiv{
        margin-top: 1%;
        margin-bottom: 0.2rem;
    }

    .FeaturedHeading{
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0.3rem;
    }

    .DishCards{
        padding: 0.2rem;
        gap: 0.1rem; 
        grid-template-columns: repeat(2,1fr);
    }

    .featuredFood{
        padding: 0rem;
        min-width: 100%;
    }

    .featured{
        gap: 5px;
        align-items: center;
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .join_team_section {
        margin-top: 0.5rem;
        padding: 0.1rem;
        width: 100%;
        height: fit-content;
    }

    .join_team_div {
        width: 100%;
    }

    .paragraph_div{
        margin-bottom: 2%;
    }

    .join_team_heading{
        font-size: 1rem;
        margin-bottom: 0;
    }

    .join_team_grid_div {
        display: grid;
        grid-template-columns: repeat(2 ,1fr);
        gap: 1px;
        width: 100%;
    }

    #video-Ad{
        display: none;
    }

    .join_team_choices {
        text-align: center;
        border: none;
        width: 10rem;
    }

    .join_title{
        padding: 0.3rem;
        font-size: 0.8rem;
    }

    .join_explanation{
        font-size: 0.7rem;
    }

    .join_team_image_div{
        width: 6.5rem;
        height: 6.5rem;
        margin-bottom: 0.2rem;
    }

    .join_img {
        width: 100%;
        height: 100%;
    }

    .signup {
        padding: 0.3rem;
        font-size: 0.7rem;
        width: 5rem;
    }
    
    .about_us_section {
        padding: 0.5rem 0.1rem;
        width: 100%;
        height: fit-content;
        margin-bottom: 0;
    }

    .about_us_div {
        width: 100%;
        padding: 0;
    }

    .about_us_title{
        font-size: 1.3rem;
        padding-bottom: 0;
        font-weight: 700;
    }

    .about_us_content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .aboutUs_img_div {
        border: 1px solid red;

        text-align: center;
        margin-bottom: 15px;
        margin-bottom: 0;
        display: none;
    }

    .aboutUs_img_div1{
        height: 8rem;
        width: 9rem;
    }

    .aboutUs_img_div2{
        height: 8.8rem;
        width: 9rem;
    }

    .about_us_img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .aboutUs_paragraph {
        font-size: 14px;
        line-height: 1.5;
        text-align: justify;
        padding: 0 10px;
    }
} 

@media (min-width: 321px) and (max-width: 375px){
    .containerDiv {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 0px;
        overflow: hidden;
        /* padding: 0px; */
    }

    .headerSection {
        height: auto; 
        width: 100%;
        margin-bottom: 2%;
        border-bottom: 1px solid rgba(128, 128, 128, 0.577);
    }

    .logo-CTA {
        flex-direction: row; 
        align-items: center;
        /* width: 80%; */
        padding: 0.5rem 0.3rem;
        width: 100%;
    }

    .land_logo {
        margin-bottom: 0;
    }

    .landing_sign {
        padding: 0.5rem 1rem; 
        font-size: 0.8rem; 
        margin-right: 0;
    }

    .headerSection{
        margin: 0;
    }

    .slogan{
        margin-bottom: 0;
    }

    .sloganParagraph{
        font-size: 1.2rem;
        margin-bottom: 0;
        font-weight: 200;
    }

    .services {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0px;
        margin-bottom: 30px;
        padding: 0rem 0.3rem;
    }

    .serviceDiv{
        width: 8rem;
        height: 10rem;
        padding: 0;
    }

    .serviceImg{
        width: 5.5rem;
        height: 5.5rem;
    }

    .more_icon{
        font-size: 6em;
        margin-top: -1.2rem;
    }

    .serviceDiv p{
        font-size: 0.8rem;
        bottom: 1rem;
        margin-bottom: 0;
        width: 8rem;
    }

    .offersSection{
        width: 100%;
        padding: 0;
        padding-top: 0.5rem;
    }

    .categories {
        padding: 3px 7px;
        font-size: 0.6rem;
    }

    .search-container{
        width: 12em;
    }

    .search-input {
        /* width: 50px; */
        font-size: 0.7rem;
        height: 1.5rem;
        padding: 10px 10px;
    }

    .search-btn {
        width: 2rem;
        padding: 0.1rem;
        height: 1.6rem;
    }

    .title-offerSearch{
        margin-bottom: 0;
        padding: 0;
        padding-left: 0.5rem;
        text-align: left;
    }

    .offerTitle{
        font-size: 1.1rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    .category-dispaly{
        width: 100%;
        padding: 0rem 0.2rem;
    }

    .offerNavContainer{
        padding: 0rem 0.2rem;
    }

    .featuredSection {
        width: 100%;
        padding: 5px 5px;
        margin-bottom: 2%;
    }

    .FeaturedHeading {
        font-size: 1rem;
        margin-bottom: 0rem;
    }

    .DishCards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        gap: 1px;
        width: 100%;
        padding: 0.2rem;
    }

    .restaurant-card {
        padding-top: 5px;
        margin-bottom: 1px;
        width: 185px;
        text-align: center;
    }

    .restaurantHeading {
        font-size: 11px;
    }

    .restaurantCategory {
        margin: 2px 0;
        font-size: 10px;
    }

    .star {
        font-size: 20px;
    }

    .restaurantAverage{
        margin-bottom: 0.5rem;
        font-size: 0.6rem;
    }

    .featured {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2px;
    }

    .join_team_section {
        width: 100%;
        height: fit-content;
        padding: 5px;
    }

    .join_team_heading {
        font-size: 1rem;
    }

    .join_team_grid_div {
        grid-template-columns: repeat(2, 1fr);
        gap: 7px;
        width: 100%;
    }

    #video-Ad{
        display: none;
    }

    .join_title {
        padding: 10px;
        margin-bottom: 5px;
        font-size: 1rem;
    }

    .join_explanation{
        font-size: 0.8rem;
    }

    .join_team_image_div{
        width: 7rem;
        height: 7rem;
        margin-bottom: 0.2rem;
    }

    .join_img {
        width: 100%;
        height: 100%;
    }

    .signup {
        padding: 0.3rem;
        font-size: 0.7rem;
        width: 5rem;
    }

    .about_us_section {
        padding: 0.5rem 0.5rem;
        width: 100%;
        height: fit-content;
        margin-bottom: 0;
    }

    .about_us_div {
        width: 100%;
        padding: 0;
    }

    .about_us_title{
        font-size: 1.3rem;
        padding-bottom: 0;
        font-weight: 700;
    }

    .about_us_content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .aboutUs_img_div {
        border: 1px solid red;

        text-align: center;
        margin-bottom: 15px;
        margin-bottom: 0;
        display: none;
    }

    .aboutUs_img_div1{
        height: 8rem;
        width: 9rem;
    }

    .aboutUs_img_div2{
        height: 8.8rem;
        width: 9rem;
    }

    .about_us_img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .aboutUs_paragraph {
        font-size: 14px;
        line-height: 1.5;
        text-align: justify;
        padding: 0 10px;
    }
    
}


/* @media (min-width: 375px) and (max-width: 480px){ */
@media (min-width: 376px) and (max-width: 425px){
    .containerDiv {
        max-width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 0px;
        overflow: hidden;
    }

    .headerSection {
        height: auto; 
        width: 100vw;
        margin-bottom: 2%;
        border-bottom: 1px solid rgba(128, 128, 128, 0.577);
    }

    .logo-CTA {
        flex-direction: row; 
        align-items: center;
        padding: 0.5rem 0.5rem;
        width: 100%;
    }

    .land_logo {
        margin-bottom: 0;
    }

    .landing_sign {
        padding: 0.5rem 1rem; 
        font-size: 0.8rem; 
        margin-right: 0;
    }

    .headerSection{
        margin: 0;
    }

    .services_slogan{
        margin-top: 0;
    }

    .slogan{
        margin-bottom: 0;
    }

    .sloganParagraph{
        font-size: 1.2rem;
        margin-bottom: 0;
        font-weight: 200;
    }

    .services{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
        margin-bottom: 0;
        row-gap: 0;
    }

    .serviceDiv{
        width: 9rem;
        height: 11rem;
        padding: 0;
    }

    .serviceImg{
        width: 6rem;
        height: 6rem;
    }

    .serviceDiv p{
        font-size: 0.8rem;
        bottom: 1rem;
        margin-bottom: 0;
        width: 8rem;
    }

    .offersSection{
        width: 100%;
        padding: 0rem 0.1rem;
        padding-top: 1rem;
    }

    .title-offerSearch{
        margin-bottom: 0;
        padding: 0;
        padding-left: 1rem;
        text-align: left;
    }

    .offerTitle{
        font-size: 1.1rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    .category-dispaly{
        width: 100%;
    }

    .offerNavContainer{
        padding: 0rem 0.2rem;
    }

    .allBtn{
        display: none;
    }

    .categories:focus{
        font-size: 0.8rem;
        padding: 0.2rem 0.5rem;
    }

    .search-container{
        width: 15em;
    }

    .search-input{
        padding-top: 0.2rem 0rem;
        padding-left: 0.2rem;
        width: 80%;
        font-size: 0.7rem;
        outline: none;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        flex: none;
    }

    .search-btn{
        padding: 0.4rem;
    }

    .offers-container{
        margin-top: 0.5rem;
    }

    .offerDispaly{
        margin-top: 0%;
    }

    .offerDisplay{
        margin-top: 0.5rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 0.6rem;
        row-gap: 0.9rem;
        padding: 0rem 0.2rem;
    }

    .featuredSection{
        padding: 0px;
        width: 100vw;
    }

    .divHotel{
        width: 100%;
    }

    .FeaturedDiv{
        margin-top: 1%;
    }

    .FeaturedHeading{
        font-size: 1.1rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .DishCards{
        padding: 0.2rem;
        gap: 0.1rem; 
        grid-template-columns: repeat(2,1fr);
    }

    .featuredFood{
        padding: 0rem;
        min-width: 100%;
    }

    .featured{
        gap: 5px;
        align-items: center;
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .join_team_section {
        margin-top: 1rem;
        padding: 0.6rem;
        width: 100%;
        height: fit-content;
    }

    .join_team_div {
        width: 100%;
    }

    .paragraph_div{
        margin-bottom: 10%;
    }

    .join_team_heading{
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 0;
    }

    .join_team_grid_div {
        display: grid;
        grid-template-columns: repeat(2 ,1fr);
        gap: 10px;
        width: 100%;
    }

    #video-Ad{
        display: none;
    }

    .join_team_choices {
        text-align: center;
        border: none;
        width: 12rem;
    }

    .join_title{
        padding: 0.3rem;
        font-size: 1rem;
    }

    .join_explanation{
        font-size: 0.8rem;
    }

    .join_team_image_div{
        width: 7rem;
        height: 7rem;
        margin-bottom: 0.2rem;
    }

    .join_img {
        width: 100%;
        height: 100%;
    }

    .signup {
        padding: 0.3rem;
        font-size: 0.7rem;
        width: 5rem;
    }
    
    .about_us_section {
        padding: 0.5rem 0.5rem;
        width: 100%;
        height: fit-content;
        margin-bottom: 0;
    }

    .about_us_div {
        width: 100%;
        padding: 0;
    }

    .about_us_title{
        font-size: 1.3rem;
        padding-bottom: 0;
        font-weight: 700;
    }

    .about_us_content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .aboutUs_img_div {
        border: 1px solid red;

        text-align: center;
        margin-bottom: 15px;
        margin-bottom: 0;
        display: none;
    }

    .aboutUs_img_div1{
        height: 8rem;
        width: 9rem;
    }

    .aboutUs_img_div2{
        height: 8.8rem;
        width: 9rem;
    }

    .about_us_img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .aboutUs_paragraph {
        font-size: 14px;
        line-height: 1.5;
        text-align: justify;
        padding: 0 10px;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .containerDiv {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 0px;
        overflow: hidden;
    }
    
    .logo-CTA {
        padding: 10px 0.2rem;
        width: 100%;
    }

    .landing_sign {
        padding: 7px 15px;
        font-weight: 700;
        font-size: 17px;
        font-style: normal;
        border: none;
        margin-right: 0cm;
        background: var(--green);
        color: white;
    }

    .services-serchBar {
        margin-top: 4%;
    }

    .search__input {
        width: 550px;
    }

    .search-input:hover,.search-input:focus{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    
    .services {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 5px;
        column-gap: 3px;
        margin-bottom: 10px;
    }

    .services_slogan {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 15px;
    }

    .slogan {
        margin-bottom: 10px;
    }

    .sloganParagraph {
        font-size: 35px;
        /* margin-bottom: 0.5rem; */
    }

    .serviceImg {
        width: 150px;
        height: 150px;
        margin-bottom: 5px;
        background: var(--orange);
        top: 5px;
    }

    .more_icon {
        font-size: 9.5em;
        margin-top: -2.2rem;
    }

    .serviceDiv p {
        font-size: 1rem;
        padding: 0px;
    }

    .featured {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 5px;
        row-gap: 20px;
    }

    .DishCards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        gap: 15px;
    }

    #video-Ad {
        background: none;
        border: none;
    }


    .join_team_section {
        width: 100%;
        height: fit-content;
        padding: 10px 5px;
        margin-bottom: 3%;
    }

    .join_team_grid_div {
        grid-template-columns: repeat(3,1fr);
        gap: 10px;
        width: 85%;
    }

    .join_team_image_div {
        width: 250px;
        height: 250px;
    }
    .join_img {
        width: 250px;
        height: 250px;
    }

    .join_team_heading{
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 0;
    }

    .join_team_div {
        width: 100%;
    }

    .about_us_section {
        width: 100%;
        height: fit-content;
    }

    .aboutUs_img_div {
        margin: 20px;
    }

    .aboutUs_img_div1 {
        width: 250px;
        height: 250px;
    }

    .aboutUs_img_div2 {
        width: 150px;
        height: 200px;
    }

    .aboutUs_paragraph {
        font-size: 0.8rem;
    }

    .offersSection {
        width: 90%;
        padding: 2px 5px;
    }

}

@media (min-width: 769px) and (max-width:1024px) {
    .containerDiv {
        width: 100vw;
        min-height: 100vh;
    }
    
    .logo-CTA {
        padding: 10px 0.5rem;
        width: 100vw;
    }

    .landing_sign {
        margin-right: 0cm;
    }

    .headerSection {
        margin-bottom: 1%;
    }

    .services-serchBar {
        margin-top: 4%;
    }

    .search__input {
        width: 55em;
        font-size: 0.9rem;
    }

    .services {
        grid-template-columns: repeat(7, 1fr);
        gap: 5px;
    }

    .serviceDiv {
        height: 200px;
        width: 190px;
        padding: 15px 5px;
    }

    .serviceImg {
        width: 140px;
        height: 140px;
        margin-bottom: 5px;
        background: var(--orange);
        top: 5px;
    }

    .more_icon{
        font-size: 9em;
        margin-top: -1.2rem;
    }

    .serviceDiv p {
        font-size: 1.1rem;
        padding: 0px;
    }

    .offersSection {
        width: 100%;
        padding: 2px 0.5rem;
    }

    .offerTitle {
        font-size: 1.4rem;
        margin-bottom: 0rem;
    }

    .categories {
        padding: 5px 9px;
        font-size: 1rem;
    }

    .search-btn {
        width: 2.1rem;
    }

    .search-btn svg{
        font-size: 1.3rem;
    }

    .search-input {
        height: 35px;
        padding: 0.2rem;
        font-size: 1rem;
    }

    .search-input:hover,.search-input:focus{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .offers-container {
        margin-top: 1.5rem;
    }

    .offerDisplay {
        grid-template-columns: repeat(4, 1fr);
        flex-wrap: nowrap;
        column-gap: 5px;
    }

    .featuredSection {
        width: 100%;
        padding: 1rem 1rem;
        min-height: fit-content;
    }

    .FeaturedDiv {
        margin-bottom: 1.5%;
    }

    .FeaturedHeading {
        font-size: 1.4rem;
        margin-bottom: 0rem;
    }

    .featured {
        grid-template-columns: repeat(6, 1fr);
        column-gap: 5px;
        row-gap: 20px;
    }

    .DishCards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        gap: 5px;
    }

    .join_team_section {
        width: 100%;
        height: fit-content;
        padding: 10px 5px;
        margin-bottom: 3%;
    }

    .paragraph_div {
        width: 70%;
    }

    .join_team_grid_div {
        grid-template-columns: repeat(3,1fr);
        gap: 10px;
        width: 85%;
    }

    #video-Ad {
        background: none;
        border: none;
    }

    .join_team_image_div {
        width: 250px;
        height: 250px;
    }
    .join_img {
        width: 250px;
        height: 250px;
    }

    .about_us_section {
        width: 100%;
        height: fit-content;
    }

    .aboutUs_img_div {
        margin: 20px;
    }

    .aboutUs_img_div1 {
        width: 250px;
        height: 250px;
    }

    .aboutUs_img_div2 {
        width: 150px;
        height: 200px;
    }

    .aboutUs_paragraph {
        font-size: 1rem;
    }
} 

@media(min-width:1025px) and (max-width:1440px){
    .containerDiv {
        max-width: 100vw;
        min-height: 100vh;
    }
    
    .logo-CTA {
        padding: 10px 0.5rem;
        /* width: 100vw; */
    }

    .landing_sign {
        margin-right: 0cm;
    }

    .headerSection {
        margin-bottom: 1%;
    }

    .services-serchBar {
        margin-top: 4%;
    }

    .search__input {
        width: 55em;
        font-size: 0.9rem;
    }

    .services {
        grid-template-columns: repeat(7, 1fr);
        gap: 5px;
    }

    .serviceDiv {
        height: 185px;
        width: 185px;
        padding: 0px;
    }

    .serviceImg {
        width: 140px;
        height: 140px;
        margin-bottom: 5px;
        background: var(--orange);
        top: 5px;
    }

    .more_icon{
        font-size: 9em;
        margin-top: -1.2rem;
    }

    .serviceDiv p {
        font-size: 1rem;
        padding: 0px;
        width: 250px;
        margin-bottom: 0.3rem;
    }

    .offersSection {
        /* width: 100vw; */
        padding: 2px 0.5rem;
    }

    .offerTitle {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .categories {
        padding: 5px 9px;
        font-size: 1rem;
    }

    .search-btn {
        width: 2.1rem;
    }

    .search-btn svg{
        font-size: 1.3rem;
    }

    .search-input {
        height: 35px;
        padding: 0.2rem;
        font-size: 1rem;
    }

    .search-input:hover,.search-input:focus{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .offers-container {
        margin-top: 1.5rem;
    }

    .offerDisplay {
        grid-template-columns: repeat(6, 1fr);
        flex-wrap: nowrap;
        column-gap: 5px;
    }

    .featuredSection {
        /* width: 100%; */
        padding: 1rem 1rem;
        min-height: fit-content;
    }

    .FeaturedDiv {
        margin-bottom: 1.5%;
    }

    .FeaturedHeading {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
    }

    .featured {
        grid-template-columns: repeat(6, 1fr);
        column-gap: 5px;
        row-gap: 20px;
    }

    .DishCards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        gap: 5px;
    }

    .join_team_section {
        width: 80%;
        height: fit-content;
        padding: 10px 5px;
        margin-bottom: 3%;
    }

    .paragraph_div {
        width: 50%;
    }

    .join_team_div {
        width: 100%;
    }

    .join_team_grid_div {
        grid-template-columns: repeat(3,1fr);
        width: 100%
    }

    #video-Ad{
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        object-fit: cover;
    }

    .join_team_image_div {
        width: 200px;
        height: 200px;
    }

    .join_img {
        width: 200px;
        height: 200px;
    }

    .about_us_section {
        width: 90%;
        height: fit-content;
    }

    .aboutUs_img_div {
        margin: 20px;
    }

    .aboutUs_img_div1 {
        width: 250px;
        height: 250px;
    }

    .aboutUs_img_div2 {
        width: 150px;
        height: 200px;
    }

    .aboutUs_paragraph {
        font-size: 1rem;
    }

    .testimonials {
        width: 90%;
    }
}