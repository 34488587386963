@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

.quotation-form {
    max-width: 500px;
    margin: auto;
    margin-top: 3rem;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    display: grid;
    grid-template-columns: 1fr;
}

.quoteHeader {
    text-align: left;
    margin-bottom: 20px;
    font-weight: 600;
    color: var(--green);
    text-decoration: underline;
}

.Quote-form-group {
    margin-bottom: 15px;
}

.Quote-form-group label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
}

.Quote-form-group input {
    width: 100%;
    padding: 8px;
    /* border: 1px solid #ccc; */
    border: none;
    border-radius: 4px;
}

.Quote-form-group input:hover {
    outline: 1px solid var(--orange);
}

.Quote-form-group input:focus {
    outline: 1px solid var(--orange);
}

.quote-submit-btn {
    width: 100%;
    padding: 10px;
    background-color: var(--green);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: ease-in .2s;
}

.quote-submit-btn:hover {
    background-color: white;
    color: var(--orange);
    outline: 1px solid var(--orange);
}
