/* Styling for the AdComponent */
.ad-component {
  margin: 20px 0;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f7f7f7;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 31%
}

.ad-heading {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 10px;
}

.ad-description {
  font-size: 1.1em;
  color: #555;
  margin-bottom: 20px;
}

.video-ad-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.flyer-ad-container {
  max-width: 100%;
}

.flyer-ad-image {
  width: 100%;
  /* max-width: 600px; */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.video-ad-container iframe,
.video-ad-container video {
  width: 100%; 
  height: 100%; 
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border: none;
}

.ad-cta {
  margin-top: 15px;
}

.cta-button {
  display: inline-block;
  padding: 12px 25px;
  background-color: rgb(30, 165, 9);;
  color: white;
  font-size: 1.2em;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: rgb(30, 165, 9);;
}

.ads-container {
    width: 100%;
    display: flex;
    justify-content: space-around; 
    gap: 10px; 
    flex-wrap: wrap; 
  }
 

  /* Media Queries */

/* For tablets (768px and below) */
@media screen and (max-width: 768px) {
  .ad-component {
    width: 48%; /* Adjust to two columns */
    margin: 10px auto; /* Center components */
  }

  .ad-heading {
    font-size: 1.6em;
  }

  .ad-description {
    font-size: 1em;
  }

  .cta-button {
    font-size: 1em;
    padding: 10px 20px;
  }
}

/* For phones (480px and below) */
@media screen and (max-width: 480px) {
  .ad-component {
    width: 100%; /* Take full width */
    margin: 10px auto; /* Add vertical spacing */
  }

  .ad-heading {
    font-size: 1.4em;
  }

  .ad-description {
    font-size: 0.9em;
  }

  .cta-button {
    font-size: 0.9em;
    padding: 8px 18px;
  }
}

/* For very small phones (320px and below) */
@media screen and (max-width: 320px) {
  .ad-component {
    width: 100%; /* Take full width */
    margin: 5px auto; /* Reduce margin for small screens */
    padding: 15px; /* Reduce padding */
  }

  .ad-heading {
    font-size: 1.2em;
  }

  .ad-description {
    font-size: 0.8em;
  }

  .cta-button {
    font-size: 0.8em;
    padding: 6px 15px;
  }
}

/* Adjusting the ads container for small screens */
@media screen and (max-width: 768px) {
  .ads-container {
    flex-direction: column; /* Stack ads vertically */
    gap: 15px; /* Add more spacing between ads */
  }
}