#forgetClose{
    text-align: right;
}

#forgetHeading{
    text-align: center;
    font-size: 1.2rem;
    margin-top: -0.5rem;
}

#forgetEmail{
    margin-bottom: 0.5rem;
}

#forgetNumber{
    margin-bottom: 0.5rem;
}

.patnerRecovery{
    width: 30em;
    padding: 0.5rem;
    margin: 0rem 0.2rem;
}
