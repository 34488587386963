@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

.search-bar-container {
  position: relative;
  width: 100%;
  max-width: 600px; 
  margin: 0 auto;
}

.input-group {
  /* border: 1px solid red; */

  /* display: flex; */
  /* flex-direction: row; */
  width: 110%;
  /* flex-wrap: nowrap; */
}

.by_btn{
  background: var(--green);
  color: white;
  padding: 0.5rem;
  border: none;
  margin-right: 1rem;
  transition: ease-in .2s;
}


.by_btn:hover{
  background: white;
  color: var(--orange);
  padding: 0.5rem;
  border: none;
  margin-right: 1rem;
  cursor: pointer;
}

.search-input {
  flex: 1;
}

.dropdown-container {
  display: flex;
  justify-content: space-around;
}

.dropdown-wrapper {
  width: 100%;
}

.dropdown {
  width: 100%;
  padding: 10px;
  font-size: 36px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  color: blue;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  box-sizing: border-box;
}

.option1 {
  color: black;
  background-color: #f9f9f9;
  font-size: 30px;
}

@media (min-width: 320px){
  .input-group {
    width: 90%;
  }

  .by_btn {
    font-size: 0.7rem;
    margin-right: 0.1rem;
    padding: 0.2rem;
  }

  .by_btn:hover {
    background: white;
    color: var(--orange);
    padding: 0.2rem;
    margin-right: 0.1rem;
  }
}

@media (min-width: 321px) and (max-width: 375px){
  .by_btn {
    font-size: 0.7rem;
    margin-right: 0.1rem;
    padding: 0.2rem;
  }

  .by_btn:hover {
    background: white;
    color: var(--orange);
    padding: 0.2rem;
    margin-right: 0.1rem;
  }
}

@media (min-width: 376px) and (max-width: 768px){
  /* .search-bar-container {
    position: relative;
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
  } */

  .search-bar-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 0.2rem;
  }

  .by_btn {
    font-size: 0.8rem;
    margin-right: 0.1rem;
    padding: 0.2rem;
  }
} 

@media(min-width:769px) and (max-width:1024px){
  .input-group {
    width: 90%;
  }

  .by_btn {
    font-size: 0.9rem;
    margin-right: 0.5rem;
    padding: 0.3rem;
  }

  .by_btn:hover {
    background: white;
    color: var(--orange);
    padding: 0.2rem;
    margin-right: 0.5rem;
}

}

@media (min-width: 1025px) and (max-width:1440px) {
  .by_btn {
    font-size: 0.9rem;
    margin-right: 1rem;
    padding: 0.3rem;
  }

  .by_btn:hover {
    padding: 0.3rem;
    margin-right: 1rem;
  }
}