/* Reset and global styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

.Conference-landing-page {
    line-height: 1.6;
    color: #333;
    background-color: #f8f9fa;
}

a {
    text-decoration: none;
    color: #333;
}

img {
    max-width: 100%;
    height: auto;
}

.con-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Header and Navbar styles */
.conference-header {
    background-color: #fff;
    width: 100%;
    padding: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.navbar-conference {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.con-nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.con-nav-links li {
    margin: 0 10px;
}

.btn {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3;
}

/* Hero section styles */
.hero {
    /* background: url('../../assets/premium_photo-1676495972627-423501500a50.avif') no-repeat center center/cover; */
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    position: relative;
}

.hero::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.hero-content {
    position: relative;
    z-index: 1;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 40px;
}

/* Conferences section styles */
.conferences {
    padding: 60px 0;
    background-color: #f9f9f9;
    text-align: center;
}

.conferences h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
}

.conference-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.conference-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex: 1 1 300px;
    text-align: center;
}

.conference-card img {
    border-radius: 10px;
    margin-bottom: 15px;
}

.conference-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.conference-card p {
    font-size: 1rem;
    margin-bottom: 15px;
}

/* Showcase section styles */
.showcase {
    padding: 60px 0;
    background-color: #fff;
    text-align: center;
}

.showcase h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
}

.showcase-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.showcase-item {
    flex: 1 1 300px;
    text-align: center;
}

.showcase-item img {
    border-radius: 10px;
    margin-bottom: 15px;
}

.showcase-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.showcase-item p {
    font-size: 1rem;
    margin-bottom: 15px;
}

.showcase-btn {
    margin-top: 20px;
}

/* Testimonials section styles */
.testimonials {
    /* padding: 60px 0; */
    background-color: #f9f9f9;
    text-align: center;
    padding: 0;
    padding-top: 1rem;
}

.testimonials h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
}

.testimonial-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.testimonial-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex: 1 1 300px;
    text-align: center;
}

.testimonial-card p {
    font-size: 1rem;
    margin-bottom: 15px;
}

.testimonial-card h4 {
    font-size: 1rem;
    font-weight: bold;
}

/* Contact section styles */
.contact {
    padding: 60px 0;
    background-color: #fff;
    text-align: center;
}

.contact h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 500px;
    margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-form button {
    align-self: center;
    margin-top: 20px;
}

/* Footer styles */
.con-footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

.con-footer p {
    font-size: 0.9rem;
}
