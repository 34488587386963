
#exampleModalLabel{
    font-weight: 800;
    text-decoration: underline;
    color: var(--green);
}

.by_reataurant_select{
    height: 100%;
    /* width: 100%; */
    transition: ease-in .2s;
    border: none;
    transition: ease-in .2s;
}

.by_reataurant_select:hover,.by_reataurant_select:focus{
    cursor: pointer;
    outline: 1px solid var(--green);
    border: none;
}

#dishesModalContent {
    /* border: 5px solid green; */

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5px;
    row-gap: 10px;
}


@media (max-width:320px){
    #exampleModalBody{
        width: 100vw;
        left: -2%;
    }

    #dishesModalContent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1px;
        row-gap: 5px;
        padding-left: 1.5px;
    }

    #exampleModalLabel {
        font-size: 14px;
    }
}

@media (min-width: 321px) and (max-width: 375px){
    .by_reataurant_select {
       font-size: 0.7rem;
    }
}

@media (min-width: 376px) and (max-width: 425px){
    .by_reataurant_select{
        border: 1px solid green;
        padding: 0.1rem;
        font-size: 0.8rem;
    }
}

@media (min-width: 425px) and (max-width: 768px) {
    .byModalContent{
        width: 150%;
        margin-left: -25%;
    }

    #dishesModalContent {
        grid-template-columns: repeat(4, 1fr);
    }

    .by_reataurant_select {
        font-size: 0.8rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    #dishesModalContent {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 5px;
        row-gap: 10px;
    }
}

@media (min-width: 1025px) and (max-width: 1440px){
    #dishesModalContent {
        grid-template-columns: repeat(4, 1fr);
    }

    .byModalContent{
        margin-top: -5%;
    }
}
