@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
  --green:rgb(30, 165, 9);
  --orange: rgb(255,165,0);
}


/* Container for the restaurant card */
.restaurant-card {
  /* border: 1px solid red; */

  border: 1px solid #ddd;
  border-radius: 8px;
  padding-top: 10px;
  margin-bottom: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
  
.restaurant-card:hover {
  transform: scale(1.03);
}
  
/* Title and category */
.restaurantHeading {
  text-transform: uppercase;
  font-size: 15px;
  font-style: normal;
  margin: 0;
  font-weight: 600;
}
  
.restaurantCategory {
  margin: 4px 0;
  color: #666;
}
 
/* Container for rating stars */
.ratingDiv {
  display: flex;
  justify-content: center;
  margin: 0px 0px;
}
  
/* Style for filled stars */
.star.filled {
  color: gold;
}
  
/* Style for star hover effect */
.star:hover,
.star.hovered {
  color: gold;
}

/* Style for individual stars */
.star {
  position: relative;
  cursor: pointer;
  font-size: 30px;
  color: #ddd;
  transition: color 0.2s ease;
}


.star::before {
  content: '\u2605'; 
  color: gold;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  white-space: nowrap;
  z-index: 1;
  transition: width 0.2s ease;
}

@media (max-width: 320px){
  .restaurant-card {
    padding: 0.4rem 0.2rem;
    width: 10rem;
    text-align: center;
  }

  .restaurantHeading{
    font-size: 0.8rem;
    font-weight: 800;
  }

  .restaurantCategory {
    margin: 2px 0;
    font-size: 0.7rem;
  }

  .star {
    font-size: 1.1rem;
  } 

  .restaurantAverage{
    margin-bottom: 0px;
    font-size: 0.8rem;
  }
}

@media (min-width: 376px) and (max-width: 480px) {
  .restaurant-card{
    padding: 0.4rem;
    width: 13rem;
    text-align: center;
  }

  .restaurantHeading{
    font-size: 0.8rem;
    font-weight: 800;
  }

  .restaurantCategory{
    font-size: 0.9rem;
  }

  .star{
    font-size: 1.4rem;
  }

  .restaurantAverage{
    margin-bottom: 0;
    font-size: 0.8rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .restaurant-card {
    padding: 5px 0.2rem;
    margin-bottom: 5px;
    width: 100%;
  }

  .restaurantHeading {
    font-size: 0.9rem;
  }

  .restaurantCategory {
    margin: 4px 0;
    font-size: 0.8rem;
  }

  .star {
    font-size: 1.6rem;
  }

  .restaurantAverage{
    font-size: 0.8rem;
  }
}
