.fresh_count{
    position: absolute;
    top: 5%; 
    right: 5%;
    font-weight: 600;
    font-size: 18px;
    color: red;
}

#fresh_cart{
    transition: ease-in .2s;
}

#fresh_cart:hover{
    color: white;
}

#cartCount2{
    position: absolute;
    top: 3%; 
    right: 4.8%;
    font-weight: 600;
    font-size: 18px;
    color: red;
}

#cart2{
    margin-right: 5%;
}

@media (max-width: 320px){
    #cart2 {
        margin-right: 2%;
    }

    #cartCount2 {
        position: absolute;
        top: 0%;
        right: 0.5%;
        font-weight: 600;
        font-size: 16px;
    }
}

@media (min-width: 321px) and (max-width: 375px){
    #cart2 {
        margin-right: 2%;
    }

    #cartCount2 {
        top: -6%;
        right: 1%;
        font-size: 16px;
        color: red;
    }
}

@media (min-width: 768px) and (max-width:1024px) {
    #cart2 {
        margin-right: 2%;
    }

    #cartCount2 {
        position: absolute;
        top: -3.5%;
        right: 2%;
    }

    #cartCount {
        top: 5%;
        right: 1%;
        font-weight: 600;
        font-size: 1.2rem;
        color: red;
    }

    .foodCartModalContent{
        width: 100%;
    }
}


@media (min-width: 1025px) and (max-width: 1440px) {
    #cart2 {
        margin-right: 2%;
    }

    #cartCount2 {
        position: absolute;
        top: -0.5%;
        right: 1.9%;
    }

    #cartCount {
        top: 5%;
        right: 1%;
        font-weight: 600;
        font-size: 1.2rem;
        color: red;
    }

    .foodCartModalContent{
        width: 100%;
    }
}


