@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'poppins','san-serif';
}

.dial-slider_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.dial-slider_container {
    padding: 0.1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.driver_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid grey;
}

.driver_slider{
    display: flex;
    justify-content: center;
    align-items: center;
}

.on_off{
    padding: 1rem;
    font-size: 1rem;
}

.toggle-switch {
    position: relative;
    width: 10em;
    height: 2.5em;
    background-color: var(--orange);
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    transition: ease-in .3s;
}

.btn_slider {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: var(--green);
    left: 5px;
    top: 5px;
    transition: left 0.3s ease-in;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.toggle-switch.active .slider {
    left: calc(100% - 35px);
    background: var(--orange);
}

.toggle-switch.active  {
    background: var(--green);
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: red;
    font-size: 18px;
}
.icon.on {
    left: 40px;
    color: red;
}
.icon.off {
    left: 60px;
}

.driver_profile{
    color: var(--green);
    font-size: 2.5rem;
    transition: ease-in .2s;
}

.driver_profile:hover,.driver_profile:focus{
    cursor: pointer;
    color: var(--orange);
}

.driver_body{
    /* border: 5px solid green; */

    width: 90%;
    height: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
}

.order_container_div {
    width: 300px;
    height: fit-content;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    transition: transform 0.3s ease;
}

.order_container_div:hover{
    transform: translateY(-5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.order_container_div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.hotel_name_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.1rem;
}

.order_p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.order_detail_input {
    font-size: 14px;
    color: #666;
}

.accept_order_btn {
    padding: 0.2rem 2rem;
    border: none;
    background-color: var(--green);
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: ease-in 0.3s;
}

.accept_order_btn:hover {
    background-color: white;
    color: var(--orange);
    outline: 1px solid var(--orange);
}

.profile-card-overlay {
    position: fixed;
    top: 4rem;
    right: -100%;
    width: 30%; 
    height: 90%;
    background-color: #ffffff;
    box-shadow: -2px 0 5px rgba(0,0,0,0.5);
    transition: right 0.3s ease;
    z-index: 1000;
}

.profile-card-overlay.show {
    right: 0;
}

.profile-cards {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.profile-cards .image {
    outline: 1px solid var(--orange);
    background: var(--orange);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.driverDasboardPic{
    height: 50%;
    width: 50%;
}

.profile-cards .image img {
    outline: 1px solid var(--orange);
    transition: all 0.5s;
}

.profile-cards .image img:hover {
    transform: scale(1.1);
}

.profile-cards .name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 0.5rem;
}

.profile-cards .location {
    font-size: 1.1rem;
    font-weight: 600;
}

.profile-cards .profile_icon {
    color: var(--green);
    margin-right: 0.2rem;
    font-size: 20px;
}

.profile-cards .Vehicle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
}

.profile-cards .number {
    font-size: 22px;
    font-weight: bold;
}

.profile-cards .vehicle_details {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.profile-cards .btn-container {
    display: flex;
    margin-top: 0.5rem;
}

.profile-cards .edit_btn {
    height: 40px;
    width: 150px;
    border: none;
    background-color: var(--green);
    color: white;
    font-size: 15px;
    transition: ease-in .2s;
}

.profile-cards .edit_btn:hover {
    outline: 1px solid var(--orange);
    background-color: white;
    color: var(--orange);
    cursor: pointer;
}



.map_div{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.order_delivery_details{
    outline: 1px solid rgba(128, 128, 128, 0.536);
    border: none;
    position: absolute;
    bottom: 2rem;
    width: 80%;
    height: fit-content;
    padding: 1rem;
}

.button_div{
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

.order_delivery_details button {
    background-color: var(--green);
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    margin-top: 10px;
    transition: ease-in .2s;
}

.order_delivery_details button:hover {
    background-color: white;
    outline: 1px solid var(--orange);
    color: var(--orange);
    cursor: pointer;

}

.logout_btn {
    height: 40px;
    width: 100px;
    border: none;
    background-color:red;
    color: white;
    font-size: 15px;
    transition: ease-in .2s;
    margin-top: 0.5rem;
}

.logout_btn:hover {
    outline: 1px solid var(--orange);
    background-color: white;
    color: var(--orange);
    cursor: pointer;
}

@media(max-width:320px){
    .dial-slider_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }

    .dial-slider_container {
        padding: 0.1rem 0.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .driver_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        border-bottom: 1px solid grey;
    }

    .driver_slider {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn_slider {
        width: 22px;
        height: 22px;
        left: 5px;
        top: 2px;
        border-radius: 50%;
    }

    .on_off {
        padding: 0.2rem;
        font-size: 0.7rem;
    }

    .toggle-switch {
        position: relative;
        width: 5em;
        height: 1.6em;
    }

    .driverTotals{
        text-align: center;
        font-size: 0.9rem;
        padding: 0rem;
        margin-bottom: 0;
    }

    .driver_profile {
        font-size: 2rem;
    }

    .profile-card-overlay {
        top: 2.5rem;
        width: 100%;
        height: 100%;
    }

    .profile-cards {
        padding: 0.5rem;
        height: 100%;
    }

    .profile-cards .image {
        padding: 10px;
    }

    .image-upload{
        width: 100%;
        text-align: center;
    }

    .profileInput{
        font-size: 0.8rem;
        text-align: center;
        width: 60%;
    }

    .profile-cards .name {
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }

    .profile-cards .location {
        font-size: 1rem;
    }

    .profile-cards .profile_icon {
        font-size: 1rem;
    }

    .profile-cards .Vehicle {
        margin-top: 1.5rem;
    }

    .profile-cards .number {
        font-size: 18px;
    }

    .profile-cards .vehicle_details {
        font-size: 0.8rem;
    }

    .profile-cards .btn-container {
        display: flex;
        margin-top: 1.5rem;
    }

    .profile-cards .edit_btn {
        height: 35px;
        width: 140px;
        font-size: 0.9rem;
    }

    .logout_btn {
        height: 40px;
        width: 100px;
        border: none;
        background-color:red;
        color: white;
        font-size: 15px;
        transition: ease-in .2s;
        margin-top: 0.5rem;
    }

    .driver_body {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2px;
        row-gap: 2px;
    }

    .order_container_div {
        gap: 0px;
        width: 100%;
        padding: 0.1rem;
    }

    .hotel_name_div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 0   rem;
    }

    .order_p {
        margin: 0;
        font-size: 0.8rem;
        color: #333;
    }

    .order_vendors{
        text-align: right;
    }

    .order_number{
        font-size: 0.7rem;
    }

    .order_detail_input {
        font-size: 0.7rem;
        color: #666;
        text-align: right;
    }

    .map_div {
        padding-top: 0rem;
        width: 100vw;
    }

    .order_delivery_details {
        bottom: 0rem;
        width: 95vw;
        padding: 1rem 0.2rem;
    }

    .order_delivery_details p {
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (min-width:321px) and (max-width:375px){
    .dial-slider_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }

    .dial-slider_container {
        padding: 0.1rem 0.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .driver_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        border-bottom: 1px solid grey;
    }

    .driver_slider {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn_slider {
        width: 22px;
        height: 22px;
        left: 5px;
        top: 2px;
        border-radius: 50%;
    }

    .on_off {
        padding: 0.2rem;
        font-size: 0.7rem;
    }

    .toggle-switch {
        position: relative;
        width: 5em;
        height: 1.6em;
    }

    .driverTotals{
        text-align: center;
        font-size: 0.9rem;
        padding: 0rem;
        margin-bottom: 0;
    }

    .driver_profile {
        font-size: 2rem;
    }

    .profile-card-overlay {
        top: 2.5rem;
        width: 100%;
        height: 100%;
    }

    .profile-cards {
        padding: 0.5rem;
        height: 100%;
    }

    .profile-cards .image {
        padding: 10px;
    }

    .image-upload{
        width: 100%;
        text-align: center;
    }

    .profileInput{
        font-size: 0.8rem;
        text-align: center;
        width: 60%;
    }

    .profile-cards .name {
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }

    .profile-cards .location {
        font-size: 1rem;
    }

    .profile-cards .profile_icon {
        font-size: 1rem;
    }

    .profile-cards .Vehicle {
        margin-top: 1.5rem;
    }

    .profile-cards .number {
        font-size: 18px;
    }

    .profile-cards .vehicle_details {
        font-size: 0.8rem;
    }

    .profile-cards .btn-container {
        display: flex;
        margin-top: 1.5rem;
    }

    .profile-cards .edit_btn {
        height: 35px;
        width: 140px;
        font-size: 0.9rem;
    }

    .logout_btn {
        height: 40px;
        width: 100px;
        border: none;
        background-color:red;
        color: white;
        font-size: 15px;
        transition: ease-in .2s;
        margin-top: 0.5rem;
    }

    .driver_body {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2px;
        row-gap: 2px;
    }

    .order_container_div {
        gap: 0px;
        width: 100%;
        padding: 0.1rem;
    }

    .hotel_name_div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 0   rem;
    }

    .order_p {
        margin: 0;
        font-size: 0.8rem;
        color: #333;
    }

    .order_vendors{
        text-align: right;
    }

    .order_number{
        font-size: 0.7rem;
    }

    .order_detail_input {
        font-size: 0.7rem;
        color: #666;
        text-align: right;
    }

    .map_div {
        padding-top: 0rem;
        width: 100vw;
    }

    .order_delivery_details {
        bottom: 0rem;
        width: 95vw;
        padding: 1rem 0.2rem;
    }

    .order_delivery_details p {
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (min-width:376px) and (max-width:425px){
    .dial-slider_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }

    .dial-slider_container {
        padding: 0.1rem 0.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .driver_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        border-bottom: 1px solid grey;
    }

    .driver_slider {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn_slider {
        width: 22px;
        height: 22px;
        left: 5px;
        top: 2px;
        border-radius: 50%;
    }

    .on_off {
        padding: 0.2rem;
        font-size: 0.7rem;
    }

    .toggle-switch {
        position: relative;
        width: 5em;
        height: 1.6em;
    }

    .driverTotals{
        text-align: center;
        font-size: 0.9rem;
        padding: 0rem;
        margin-bottom: 0;
    }

    .driver_profile {
        font-size: 2rem;
    }

    .profile-card-overlay {
        top: 2.5rem;
        width: 100%;
        height: 100%;
    }

    .profile-cards {
        padding: 0.5rem;
        height: 100%;
    }

    .profile-cards .image {
        padding: 10px;
    }

    .image-upload{
        width: 100%;
        text-align: center;
    }

    .profileInput{
        font-size: 0.8rem;
        text-align: center;
        width: 60%;
    }

    .profile-cards .name {
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }

    .profile-cards .location {
        font-size: 1rem;
    }

    .profile-cards .profile_icon {
        font-size: 1rem;
    }

    .profile-cards .Vehicle {
        margin-top: 1.5rem;
    }

    .profile-cards .number {
        font-size: 18px;
    }

    .profile-cards .vehicle_details {
        font-size: 0.8rem;
    }

    .profile-cards .btn-container {
        display: flex;
        margin-top: 1.5rem;
    }

    .profile-cards .edit_btn {
        height: 35px;
        width: 140px;
        font-size: 0.9rem;
    }

    .logout_btn {
        height: 40px;
        width: 100px;
        border: none;
        background-color:red;
        color: white;
        font-size: 15px;
        transition: ease-in .2s;
        margin-top: 0.5rem;
    }

    .driver_body {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2px;
        row-gap: 2px;
    }

    .order_container_div {
        gap: 0px;
        width: 100%;
        padding: 0.1rem;
    }

    .hotel_name_div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 0   rem;
    }

    .order_p {
        margin: 0;
        font-size: 0.8rem;
        color: #333;
    }

    .order_vendors{
        text-align: right;
    }

    .order_number{
        font-size: 0.7rem;
    }

    .order_detail_input {
        font-size: 0.7rem;
        color: #666;
        text-align: right;
    }

    .map_div {
        padding-top: 0rem;
        width: 100vw;
    }

    .order_delivery_details {
        bottom: 0rem;
        width: 95vw;
        padding: 1rem 0.2rem;
    }

    .order_delivery_details p {
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}


/* //Modal of driver earnings */
/* Ensure the modal is on top of the page */
.earnings-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

/* Style for the modal box */
.earnings-modal {
    background-color: white;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Style the button that opens the modal */
/* .driverTotals {
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
} */

/* .driverTotals:hover {
    background-color: #218838;
} */

/* Style for the table inside the modal */
.driver-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
}

.driver-table th, .driver-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.driver-table th {
    background-color: #f4f4f4;
}

/* Style for the close button */
.btn-driver {
    padding: 10px 15px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
}

.btn-driver:hover {
    background-color: #c82333;
}
