@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

.dish-list {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 10px;
    row-gap: 20px;
}

#dish-categories{
    display: grid;
    grid-template-columns: 75% 25%;
    position: relative;
    min-height: fit-content;
    width: 100%;
}

.dishesSection{
    margin-top: 0.4%;
    width: 100%;
    position: relative;
}

.dishes-to-display{
   
}

.category-buttons{
    margin-left: 2rem;
    display: flex;
    justify-content: space-between;
    width: 50%;
    padding: 0.2rem;
}

.switchButtons{
    background: var(--green);
    border: none;
    padding: 0.2rem 1.2rem;
    border-radius: 5%;
    color: white;
    transition: ease-in .2s;
}

.switchButtons:hover,.switchButtons:focus{
    background: white;
    border: 0.1px solid var(--orange);
    text-decoration: underline;
    color: var(--orange);
    cursor: pointer;
}

.category-buttons-div{
    /* border: 5px solid blue; */

    padding: 0.5rem; 
    padding-left: 5%;
    position: fixed;
    z-index: 200;
    background: white;
    width: 75%;
    display: flex;
    align-items: center;
}

.menu-category-buttons{
    background: var(--green);
    color: white;
    border: 1px solid var(--green);
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    transition: ease-in .2s;
}

.menu-category-buttons:hover,.menu-category-buttons:focus{
    background: white;
    color: var(--orange);
    border: 1px solid var(--green);
    text-decoration: underline var(--orange);
}

.menuDivs{
    /* border: 5px solid blue; */
}

.restaurantSection{
    /* border: 5px solid green; */

    position: relative;

    /* padding: 0px; */
}

.popularHotelDiv{
    position: fixed;
    right: 3%;
    height: 90vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* margin: 0px; */
}

.menuHeader{
    width: 90%;
    text-align: left;
    margin-left: 3%;
    padding: 10px 5px;
    font-weight: 700;
    text-decoration: underline;
}

.restaurantHeader{
    text-align: center;
    width: 100%;
    padding: 10px 5px;
    margin-top: 0px;
    font-weight: 700;
    text-decoration: underline;
}

#popular-restaurants{
    display: flex;
    flex-direction: column;
    padding-left: 0px;
}


@media (max-width:320px){
    #navline {
        padding-left: 0.1rem;
    }

    .category-buttons {
        width: 100vw;
        position: fixed;
        margin-left: 0rem;
        display: flex;
        justify-content: space-around;
        /* width: 100%; */
        padding: 0.1rem;
        z-index: 100;
        background-color: white;
        top: 2.9rem;
    }

    .dishes-to-display {
        margin-top: 1rem;
    }

    .dishesSection {
        margin-top: 3%;
        width: 100vw;
    }

    .switchButtons {
        padding: 0.1rem 0.6rem;
        font-size: 0.6rem;
    }

    .menuHeader {
        width: 100%;
        text-align: left;
        margin-left: 0%;
        margin-bottom: 0rem;
        font-size: 0.9rem;
    }

    .dish-list {
        grid-template-columns: repeat(2,1fr);
        column-gap: 2px;
        row-gap: 10px;
        padding-left: 0.1rem;
    }

    .restaurantSection{
        display: none;
    }

}

@media (min-width: 321px) and (max-width: 375px){
    .dish-list {
        grid-template-columns: repeat(2,1fr);
        column-gap: 2px;
        row-gap: 10px;
        padding-left: 0.1rem;
    }

    .restaurantSection{
        display: none;
    }

    .category-buttons {
        position: fixed;
        margin-left: 0rem;
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 0.1rem;
        z-index: 100;
        background-color: white;
        top: 2.9rem;
    }

    .dishesSection {
        margin-top: 0.4%;
        width: 100vw;
    }

    .switchButtons {
        padding: 0.1rem 1rem;
        font-size: 0.8rem;
    }

    .dishes-to-display {
        margin-top: 1.1rem;
    }
    
    .menuHeader {
        width: 100%;
        text-align: left;
        margin-left: 0%;
        margin-bottom: 0rem;
        font-size: 0.9rem;
    }
}

@media (min-width: 376px) and (max-width:425px){
    .restaurantSection{
        display: none;
    }

    .dishesSection{
        /* border: 5px solid green; */
    
        margin-top: 1.5%;
        width: 100%;
        position: relative;
    }

    #dish-categories{
        /* border: 5px solid yellow; */
        grid-template-columns: 100%;
    }

    .category-buttons{
        /* border: 5px solid red; */
        
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.2rem;
        margin-left: 0rem;
        position: fixed;
        background: white;
        margin-top: -1%;
        z-index: 100;
    }

    .switchButtons {
        padding: 0.1rem 1.2rem;
        font-size: 0.8rem;
    }

    .dishes-to-display {
        margin-top: 7%;
    }

    .menuHeader{
        /* border: 1px solid blue; */

        width: 98%;
        margin-top: 2%;
        margin-left: 1%;
        padding: 0.2rem 0.1rem;
        font-weight: 600;
        font-size: 1rem;
    }

    .dish-list {
        grid-template-columns: repeat(2,1fr);
        column-gap: 5px;
        row-gap: 10px;
        padding-left: 0.2rem;
        /* padding-right: 0.1rem; */
    }
}

@media (min-width:426px) and (max-width:768px){
    .dish-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 10px;
        row-gap: 20px;
    }

    .category-buttons {
        margin-left: 0rem;
        width: 60%;
        justify-content: center;
    }

    .switchButtons {
        margin: 0rem 1rem;
        padding: 0.2rem 1.2rem;
        font-size: 0.9rem;
    }

    .restaurantSection{
        display: none;
    }

    .dishesSection {
       width: 100vw;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
    }

    .menuHeader {
       font-size: 1rem;
    }
}

@media (min-width: 769px) and (max-width:1024px) {
    .category-buttons {
        margin-left: 0.9rem;
        width: 90%;
    }

    .switchButtons {
        border-radius: 0%;
    }

    .menuHeader {
        margin-left: 1%;
    }

    .dish-list {
        column-gap: 5px;
        row-gap: 10px;
        padding-left: 0.2rem;
    }

    .restaurantSection {
        padding-top: 0rem;
    }

    .popularHotelDiv {
        position: fixed;
        right: 0.5%;
        height: 90vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0px;
    }
}

@media(min-width:1025px) and (max-width:1440px){
    .category-buttons {
        margin-top: -0.2rem;
        margin-left: 0rem;
        width: 77%;
        position: fixed;
        background: white;
        z-index: 100;
        align-items: center;
        justify-content: center;
    }

    .switchButtons {
        border-radius: 0%;
        margin: 0 3rem;
    }

    .menuHeader {
        margin-left: 1%;
        margin-bottom: 0;
    }
    
    .restaurantSection {
        padding-top: 0rem;
    }

    .dish-list {
        column-gap: 5px;
        row-gap: 10px;
        padding-left: 0.2rem;
        grid-template-columns: repeat(5, 1fr);
        padding-left: 0.5rem;
    }

    .popularHotelDiv {
        position: fixed;
        right: 1.2%;
        height: 90vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0px;
    }
}