:root{
  --green:rgb(30, 165, 9);
  --orange: rgb(255,165,0);
}

* {
  margin: 0;
  box-sizing: border-box;
}

.NavBarContainer{
  /* margin-top: 5%;  */
  position: relative;
}

#navline{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  position: fixed;
  z-index: 500;
  padding: 0.1rem 0rem;
  background: var(--orange);
  top: 0;
  padding-top: 0.5rem;
}

.homeLink{
  margin-left: 5%;
}

.homeIcon{
  font-size: 20px;
  color: var(--green);
}

.homeIcon:hover{
  color: var(--orange);
  cursor: pointer;
}

.menu-container{
  margin-top: 4%; 
}

@media (max-width:320px){
  #navline {
    width: 100%;
  }

  .homeLink{
    display: none;
  }

  .search-bar-container {
    width: 100%;
    max-width: 400px;
  }

  .by_btn {
    font-size: 0.6rem;
    margin-right: 0.1rem;
    padding: 0.2rem;
  }

  .by_reataurant_select {
    padding: 0rem;
    font-size: 0.6rem;
  }

  .menu-container {
    margin-top: 14%;
  }

  .dishesSection {
    margin-top: 1%;
  }
}

@media (min-width: 321px) and (max-width: 375px){
  #navline {
    padding-top: 0.3rem;
    padding-left: 0.2rem;
  }
  
  .NavBarContainer {
   width: 100vw;
  }
 
  .homeLink{
    display: none;
  }

  .search-bar-container {
    width: 100%;
    max-width: 400px;
  }

}

/* Media screens */
@media (min-width: 376px) and (max-width: 480px) {
  .NavBarContainer{
    /* border: 5px solid red; */

    width: 100%;
  } 
  
  #navline{
    padding-left: 0.2rem;
    width: 100%;
  }

  .homeLink{
    display: none;
  }

  .menu-container{
    margin-top: 11%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .menu-container {
    margin-top: 6.5%;
  }
}

@media (min-width: 768px) and (max-width:1024px) {
  .homeLink {
    margin-left: 1%;
  }

  .menu-container{
    margin-top: 5.5%;
  }
}

@media(min-width:1025px) and (max-width:1440px){
  
}