@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
    --transition-short: all .2s ease-in-out;
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
}

.account_section{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding-top: 1%;
}

.profile_section{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
} 

#profileImageSection{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* Profile image */
.account_details {
    background: rgba(128, 128, 128, 0.118);
}

.orderButtons{
    color: white;
    background: var(--green);
    margin-right: 0.3rem;
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    transition: ease-in .2s;
    border: 1px solid var(--green);
}

.orderButtons:hover{
    color: var(--orange);
    background: white;
    border: 1px solid var(--orange);
}

#profileImageInput{
    width: inherit;
    margin: 5px;
    text-align: center;
}

.essential_image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px ;
}

.essential_details{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.profile_img_div{
    width: 70%; 
    height: 100%; 
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.account_profile_img{
    border: 1px solid var(--orange);
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.image_button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.essential_grid{
    width: 80%;
}

.essential_grid_content{
    display: grid;
    grid-template-columns: 1fr;
    height: fit-content;
}

.content_label{
    margin-bottom: 5px;
}

.content_span{
    border-bottom: 1px solid var(--green);
    padding: 10px 5px;
    width: 80%;
}

/*  OTHER CONTACTS */
.other_contacts_div{
    margin-top: 3%;
    text-align: center;
    position: relative;
    margin-bottom: 1%;
}

.other_contact_grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0px 5px;
    gap: 5px;
}

.other_titles{
    border: 1px solid rgba(0, 0, 0, 0.121);
    color: white;
    background: var(--green);
    font-size: 18px;
    padding: 5px 0px;
}

.other_sub_titles{
    background: var(--orange);
}

.other_ul{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.other_li{
    list-style: none;
    border-bottom: 1px solid var(--green);
    margin-bottom: 5%;
    width: 80%;
    justify-items: center
}

.other_contact_phone{
    width: 100%;
    text-align: center;
}

/* OTHER SERVICES OFFERED */
.other_services_div{
    text-align: center;
}

.other_services_list{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 5px;
    padding: 5px;
}

.other_service_labels{
    font-size: 16px;
    margin-left: 5px;
}

.radio_btn_div{
    width: 100%;
    display: flex; 
    align-items: center; 
    flex-direction: row;
    justify-content: center;
}

.other_service_radio:hover{
    cursor: pointer;
}

.profilePicBtn{
    padding: 5px;
    cursor: pointer;
    background-color: var(--green); 
    color: white;
    border: none;
    width: 40%;
    margin-top: 5px;
    transition: ease-in .2s;
}

.profilePicBtn:hover{
    background: white;
    color: orange;
    outline: 1px solid var(--orange);
}

.editprofileButton{
    position: absolute;
    top: 0%; 
    right: -5%; 
    background-color: var(--orange); 
    color: white;
    border: none;
    padding: 5px 10px;
    transition: ease-in .2s;
} 

.editprofileButton:hover{
    background-color: white; 
    color: var(--orange);
    cursor: pointer;
    outline: 1px solid var(--orange);
} 

.contactNumBtn{
    position: absolute;
    top: 4%; 
    right: -4%; 
    background-color: var(--green); 
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    outline: 1px solid var(--orange);
    transition: ease-in .2s;
}

.contactNumBtn:hover{
    background-color: white; 
    color: var(--orange);
    cursor: pointer;
    outline: 1px solid var(--orange);
}

.radioDivDiv{
    /* border: 1px solid; */

    margin-top: 5%;
    position: relative;
}

.radioDivBtn{
    position: absolute;
    top: 10%; 
    right: -4%; 
    background-color: var(--green); 
    outline: 1px solid var(--orange);
    color: white;
    border: none;
    padding: 5px 10px;
    transition: ease-in .2s;
}

.radioDivBtn:hover{ 
    background-color: white; 
    outline: 1px solid var(--orange);
    color: orange;
    border: none;
    cursor: pointer;
}

.content_input { 
    padding: 5px;
    border: 1px solid #ccc;
}

.editButton, .saveButton {
    margin-top: 3px;
    padding: 5px 10px;
    border: none;
    background: var(--orange);
} 

.editButton:hover, .saveButton:hover {
    cursor: pointer;
    background: white;
    color: var(--orange);
    outline: 1px solid var(--orange);
} 

.deleteButton{
    padding: 5px 10px;
    border: none;
    background: red;
    transition: ease-in .2s; 
}

.deleteButton:hover{
    cursor: pointer;
    background: white;
    color: red;
    outline: 1px solid red;
}

/* OTHER PROFILE CONTENT */
.profile_content{
    width: 95%;
    height: 100%;
}

.service_drop {
    position: absolute;
    min-width: 160px;
    z-index: 5;
    top: 100%;
    left: 30%;
}

.service_drop_link{
    border: 1px solid rgba(0, 0, 0, 0.308);
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
    background: var(--green);
    transition: ease-in .3s;
    margin-bottom: 2px;
}

.service_drop_link:hover {
    background-color:white; 
    color: var(--orange);
    border: 1px solid var(--orange);
    /* border-radius: 10px; */
} 

/* DISPLAY AREA FOR DIFFERENT CATEGORY SECTION */
.dispay_area_div{
    /* border: 5px solid green; */

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* THE MENU DEFINITION PART */
.category_menu{
    width: 100%;
    height: 100%;
}

/* MENU SEARCH INPUT FIELD */
.label_input{
    display: flex;
    flex-direction: row;
    width: 40%;
}

.search_new{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    margin-top: 10px;
    height: 6%;
}

/* search_menu_btn */
.search_menu {
    width: 100%;
    border: none;
    outline: 1px solid rgba(128, 128, 128, 0.119);
    /* padding: 5px 10px; */
    font-size: 15px;
    height: 2rem;
    padding: 2px;
    transition: ease-in 0.2s;
}

.search_menu:hover ,.search_menu:focus{
    outline: 1px solid var(--green);
    cursor: pointer;
    padding: 5px 10px;
}

.search_menu_btn{
    font-size: 15px;
    padding: 5px 10px;
    border: none;
    background: var(--green);
    color: white;
    transition: ease-in 0.2s;
    /* border-radius: 3px; */
    height: 2rem;
}

.search_menu_btn:hover{
    background-color: white;
    color: var(--orange);
    /* outline: 1px solid var(--orange); */
    border-left: .1px solid var(--orange);
    cursor: pointer;
}

/* ADDING NEW DISH BTN */
.new_dish_btn{
    font-size: 15px;
    padding: 5px 15px;
    background: var(--green);
    border: none;
    color: white;
    transition: ease-in 0.2s;
    border-radius: 3px;
}

.new_dish_btn:hover{
    background: white;
    color: var(--orange);
    cursor: pointer;
    outline: 1px solid var(--orange);
}

.menu_table{
    /* border: 5px solid red; */

    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}


.left_panel{ 
    /* border: 5px solid green; */

    height: 100%;
}

/* DIV HOLDING THE ICONS IN TABLE */
.iconHeader{ 
    /* border: 5px solid green; */

    background-color: var(--orange); 
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tableTitileChart{
    
}

#chart_title{
    /* border: 5px solid red; */
    width: 25rem;
}

/*  TITTLE CHANGING INPUT  FIELD*/
.inputHide{
    /* border: 5px solid red; */

    width: 100%;
    /* padding: 5px 10px; */
    margin-bottom: 0.2rem;
    font-size: 15px;
    height: 2rem;
    border: none;
    padding-left: 5px;

    -webkit-transition: var(--transition-short);  -moz-transition: var(--transition-short);  
    -o-transition: var(--transition-short); 
    transition: var(--transition-short);
}

.inputHide:hover,.inputHide:focus{ 
    cursor: pointer; 
    outline: 1px solid var(--green);
    padding: 5px 10px;
}

.func_div{
    /* border: 5px solid green; */

    position: absolute;
    left: 100%;
    top: -6%; 
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: space-between;
}

.func_icons{
    /* position: absolute;
    left: 100%;
    top: -6%; */
    font-size: 0.8rem;
    padding: 0.3rem 0.4rem;
    text-align: center;
    margin-right: 0.9rem;
    background: var(--green);
    border: none;
    color: white;
    transition: ease-in .2s;
}

.func_icons_delete{
    border: none;
    background: red;
    transition: ease-in .2s;
}

.func_icons_delete:hover{
    background: white;
    color: red;
    outline: 1px solid red;
}

.func_icons_close:hover{
    color: var(--white); 
    background: white;
    color: var(--orange);
    outline: 1px solid var();
    cursor: pointer;
}

/* TABLE TITLE */
.h2TableTitle{
    /* border: 5px solid red; */

    color: white;
    font-size: 1.6rem;
    position: relative;
    padding: 0px;
    width: fit-content;
}

.vendorLocation{
    color: white;
    padding: 0px;
    text-align: left;
}

/* PENCIL ICON TO EDIT MENU TABLE TITLE  */
.hideshow { 
    color: var(--white); 
    /* font-size: .8rem; */
    /* width: 6%; */
    /* background: var(--green); */
    border: none;
    color: white;
    margin-left: 5px;
}

.hideshow:hover{ 
    color: var(--white); 
    background: white;
    color: var(--orange);
    outline: 1px solid var();
    cursor: pointer;
}

.addRestaurantButton{
    /* padding: 3px 20px; */
    padding: 0.2rem 0.7rem;
    border: none;
    background: var(--green);
    color: white;
    transition: ease-in .2s;
    margin-bottom: 1.5%;
    margin-top: 15%;
}

.addRestaurantButton:hover{
    cursor: pointer;
    background: white;
    color: var(--orange);
    outline: 1px solid green;
}

.addShopButton{
    padding: 0.2rem 0.7rem;
    border: none;
    background: var(--green);
    color: white;
    transition: ease-in .2s;
    margin-bottom: 1.5%;
    margin-top: 17%;  
}

.addShopButton:hover{
    cursor: pointer;
    background: white;
    color: var(--orange);
    outline: 1px solid green; 
}

.choose_select{
    border: none;
    padding: 0.4rem 0.4rem;
    border-radius: 0px;
}

.choose_select:hover,.choose_select:focus{
    border: none;
    cursor: pointer;
    outline: 1px solid var(--green);
}

.choose_select_options{
    padding: 5px 10px;
}

.location-section{
    margin-top: .2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.orUseBtn{
    margin-top: .5rem;
    border: none;
    background: var(--green);
    padding: 0.2rem 0.7rem;
    color: white;
    transition: ease-in .2s;
}

.orUseBtn:hover,.orUseBtn:focus{
    background: white;
    outline: 1px solid var(--orange);
    color: var(--orange);
}

.tableEditLabels{
    /* border: 1px solid red; */

    margin-left: 0.5rem;
    text-align: left;
}

.tableEditInput{
    border: none;
    padding-left: 0.3rem;
    width: fit-content;
}

.locationtableEdit{
    width: 30rem;
}

.tableEditInput:focus,.tableEditInput:active{
    border: none;
    outline: 1px solid var(--green);
}

/* PLUS ICON TO ADD TABLE ROWS INTO TTHE TABLE */
.addRowBtn{
    width: auto; 
    position: absolute; 
    left: 95%;     
    font-size: 2rem;    
    background-color: var(--green);    
    padding: .1rem .9rem; 
    border-radius: 2rem; 
    margin-top: -6%; 
    z-index: 999999999999999; 
    opacity:1; 
    border: none;
    color: white;
    /* -webkit-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.45); */
    /* -moz-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.45); */
    /* box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.45);  */
    -webkit-transition: var(--transition-short);  -moz-transition: var(--transition-short);  
    -o-transition: var(--transition-short);
    transition: var(--transition-short); 
}

.addRowBtn:hover {
    background: white;
    /* opacity:.8;  */
    cursor: pointer;
    outline: 1px solid var(--orange); 
    color: var(--orange);
}

/* DIV HOLDING INPUT FIELDS TO FILL THE TABLE  */
#chart_table{
    padding:0.1rem; 
}

/* FORM HOLDING INPUT FIELDS TO FILL THE TABLE */
#chart_datas{ 
    /* border: 5px solid green; */

    width: 100%;
    height: fit-content;
    position: relative;
    padding: 10px 0px; 
    margin-top: 0.5%;
}

.input_wrp{
    /* border: 5px solid red; */

    display: grid;
    grid-template-columns: repeat( 7, 1fr);
    gap: 5px;
    height: 1.8rem;
}

#map99{
    width: '100%';
    height: '400px';
}

.headerInputs{ 
    border-top: 0px; 
    border-left: 0px; 
    border-right: 0px; 
    background-color: rgba(255,255,255,.30); 
    border-bottom: 1px solid var(--orange);
    transition: ease-in 0.2s;
    padding-left: 2px;
}

.headerInputs:hover, .headerInputs:focus{ 
    border: none;
    cursor: pointer;
    outline: 1px solid var(--orange);
    padding: 2px 5px;
}

#addingPlus{
    color: white;
    transition: ease-in .2s;
}

.addRowBtn:hover #addingPlus{
    color: var(--orange);
}

.small-1-input{
    width: 100%;
    border: none;
}

.small-1-input:hover,.headerInputs:focus{
    padding: 0px;
    outline: none;
}

.menu_top_icon, .right_nav .fa-heart, .right_nav .fa-li{               
    font-size:1.3rem; 
    color:var(--sangria);
}

.right_nav .fa-user-circle{ 
    font-size:1.3rem; 
    margin-left:1rem; 
}

.right_nav .fa-link{ 
    margin-left:1rem;
}

.right_nav{
    float:right;
}

/* CREATING AND ADDING A ROW INTO THE MENU TABLE */
.tableRows{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around; 
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc; 
}

.headerDiv {
    padding: 5px 10px;
    /* background-color: #f2f2f2;  */
    background-color: var(--green); 
    color: white;
    font-weight: bold; 
    text-align: center;
    width: 12%; 
    font-size: 15px;
}

.headerspecial{
    width: 10%; 
}

.dishDiv{
    /* border: 1px solid green; */

    text-align: center;
    width: 12%; 
    padding: 5px 10px;
}

.dishdiv_button{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* width: 8%; */
}

.dishImagePartner {
    width: 60px;
    height: auto;
}
/* CONFERENCE STYLINGS */
.conference_wapper{
    width: 100%;
}

.conference-form-grid{
    border: 1px solid grey;

    padding: 10px 5px; 
}

.conference-input-grid{
    /* border: 1px solid grey; */

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 10px 5px;
    width: 90%;
}

.conference-inputs{
    padding: 5px ;
    border: none;
    outline: 1px solid rgba(128, 128, 128, 0.247);
}

.conference-inputs:hover,.conference-inputs:focus{
  cursor: pointer;
  outline: 1px solid var(--orange);
  color: var(--orange);
}


.checkbox-input-grid{
    /* border: 1px solid grey; */

    display: grid;
    grid-template-columns: repeat(4 , 1fr);
    gap: 5px;
    width: 100%;
}

.checkbox_divs{
    border: 1px solid rgba(128, 128, 128, 0.279);
    padding: 0px 5px;
}

.checkbox_divs:hover,.checkbox_divs:focus{
    cursor: pointer;
    outline: 1px solid var(--orange);
}

.check_box_containers{
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

.check_box_containers .yesNo-grids input{
    cursor: pointer;
}

.yesNo-grids{
  display: flex;  
  flex-direction: row;
}

.conference_submit_btn{
    background: var(--green);
    color: white;
    padding:  5px;
    border: none;
    cursor: pointer;
    transition: ease-in .2s;
}

.conference_submit_btn:hover{
    background: white;
    color: var(--orange);
    outline: 1px solid var(--orange);
}


/* CONFERENCE DISPALY TABLE */
.tableHeader{
    border:1px solid red;

    width: 100%;
    background: var(--orange);
    display: grid;
    grid-template-columns: repeat(2 ,1fr);
    gap: 10px;
    padding: 10px 5px;
    margin-bottom: .5%;
}

.confereenceTitileChart{
    /* border:  5px solid green; */
    width: 100%;
}

.CONFERENCE_input{
    /* border:  5px solid green; */
    width: 100%;
}

.conference-pencil { 
    color: var(--white); 
    font-size: .9rem;
    width: 5%;
    background: var(--green);
    border: none;
    color: white;
    margin-left: 5px;
    padding: 2px;
}

.conference-pencil:hover{ 
    color: var(--white); 
    background: white;
    color: var(--orange);
    outline: 1px solid var();
    cursor: pointer;
}

/* search_menu_btn */
.CONFERENCE_input {
    display: flex;
    flex-direction: row;
}

.conference-search_menu {
    width: 100%;
    border: none;
    outline: 1px solid rgba(128, 128, 128, 0.119);

    font-size: 15px;
    height: 3rem;
    padding: 3px;
    transition: ease-in 0.2s;
}

.conference-search_menu:hover ,.conference-search_menu:focus{
    outline: 1px solid var(--green);
    cursor: pointer;
    padding: 5px 10px;
}

.search_conference_btn{
    font-size: 15px;
    padding: 5px 10px;
    border: none;
    background: var(--green);
    color: white;
    transition: ease-in 0.2s;
    /* border-radius: 3px; */
    height: 3rem;
}

.search_conference_btn:hover{
    background-color: white;
    color: var(--orange);
    /* outline: 1px solid var(--orange); */
    border-left: .1px solid var(--orange);
    cursor: pointer;
}

.conference_add_div{
    /* border: 1px solid red; */
    text-align: right;
    padding-right: 2%;
}

.conference_add_btn{
    padding: 3px 15px;
    background: var(--green);
    color: white;
    border: none;
    border-radius: none;
}

.conference-modal{
    border: 5px solid red;
    width: 100%;
    height: 100vh;
}

.conference_table_headers{
    width: 100%;
    display: flex;
    justify-content: space-around; 
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc; 
    gap: 5px;
    margin-top: 1%;
}
.modal {
    width: 100%;
    --bs-modal-content: 1000px
}
.conference-modal{
    width: 100%;
}

.button-group{
    margin-bottom: 0.5rem;
}

.orderCurrierDiv{
    /* border: 5px solid green; */
    width: 100%;
}





.tablesSalesDiv{
    /* border: 5px solid red; */

    width: 100%;
    min-height: 100vh;
}

.button-group{
    /* border: 5px solid green; */

    width: inherit;
}

.tablesSalesBtn{
    border: 1px solid var(--green);
    background: var(--green);
    color: white;
    padding: 0.3rem 0.4rem;
    margin-right: 0.8rem;
    transition: ease-in .2s;
}

.tablesSalesBtn:hover,.tablesSalesBtn:focus{
    border: 1px solid var(--orange);
    background: white;
    color: var(--orange);
}

.content{
    /* border: 5px solid blue;  */

    width: inherit;
    height: 93%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.contenttabledivs{
    /* border: 5px solid burlywood; */
}

@media(max-width:320px){
    .homeLinkUser{
        display: none;
    }


    .profile_content{
        position: relative;
        width: 100%;
        padding: 0rem 0.2rem;
    }

    .profile_section{
        align-items: start;
        width: 100%;
        margin-top: 8%;
    } 

    .coming_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 0.3rem;
    }

    .grid_profile_one {
        width: 100%;
    }

    .essential_image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.1rem;
    }

    .image_button {
        gap: 0.2rem;
        margin-top: 0.1rem;
    }

    .profilePicBtn {
        padding: 0.2rem 0.3rem;
        width: 30%;
        font-size: 0.9rem;
    }

    .profile_img_div {
        width: 40%;
        height: 55%;
    }

    #profileImageInput {
        font-size: 0.5rem;
    }

    .editButton, .saveButton {
        padding: 0.1rem 0.2rem;
        font-size: 0.8rem;
        right: 1%;
    }

    .essential_details {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .essential_grid {
        width: 75%;
    }

    .editprofileButton {
        right: 1%;
        padding: 0.1rem 0.3rem;
        font-size: 0.8rem;
    }

    .content_input {
        padding: 0.2rem;
        font-size: 0.8rem;
    }

    .other_contacts_div {
        background: rgba(128, 128, 128, 0.118);
        margin-top: 1%;
        text-align: center;
        position: relative;
        margin-bottom: 0.5rem;
    }

    .other_titles {
        font-size: 0.9rem;
        padding: 0.1rem 0.2rem;
        margin-bottom: 0.1rem;
    }

    .error-message {
        font-size: 0.8rem;
        margin-bottom: 0px;
        padding: 0.2rem 0rem;
    }

    .other_contact_grid {
        padding: 0rem;
        gap: 3px;
        display: flex;
        flex-direction: column;
    }

    .other_contacts {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .other_sub_titles{
        width: 70%;
        font-size: 1rem;
    }

    .other_ul {
        padding: 0px;
        margin-bottom: 0.5rem;
    }

    .other_li {
        margin-bottom: 1%;
        width: 17rem;
    }

    .other_contact_phone {
        font-size: 0.9rem;
        color: black;
    }

    .radioDivDiv {
        margin-top: 1%;
    }

    .other_services_list{
        display: grid;
        grid-template-columns: repeat(3 ,1fr);
        row-gap: 0.9rem;
    }

    .radio_btn_div {
        flex-direction: column;
        justify-content: left;
    }

    .other_service_radio{
        font-size: 2rem;
    }

    .other_service_labels {
        font-size: 10px;
        margin-left: 0px;
    }


    .iconHeader {
        width: 100%;
        padding: 0.9rem 0.3rem;
        display: grid;
        justify-content: left;
    }

    .profile_section{
        align-items: start;
        width: 100%;
        margin-top: 10%;
    } 

    .orderButtonsDiv{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.2rem;
    }

    .orderButtons{
        font-size: 0.9rem;
        border:  1px solid var(--green);
        background: var(--green);
        color: white;
        transition: ease-in .2s;
    }

    .orderButtons:focus{
        color: var(--orange);
        border: 1px solid var(--orange);
        background: white;
    }

    .menu_table {
        width: 100%;
    }

    .h2TableTitle {
        font-size: 1rem;
        margin-bottom: 0rem;
    }

    #map99{
        width: '100%'
    }

    .addRestaurantButton {
        padding: 0.3rem 0.8rem;
        font-size: 0.8rem;
        margin-bottom: 1%;
        margin-top: 15%;
    }

    .vendorLocation {
        font-size: 0.7rem;
    }

    
    .forgotten{
        width: 95vw;
        margin-top: 10%;
    }

    .locationtableEdit {
        width: 25rem;
    }

    .tableEditLabels{
        margin-left: 0rem;
        text-align: left;
    }

    .tableBestIn{
        margin-left: 0.5rem;
    }

    .func_icons {
        font-size: 1.2rem;
        padding: 0.2rem 0.3rem;
    }

    .restaurantSelection{
        font-size: 0.8rem;
    }

    .choose_select{
        padding: 0.1rem 0.3rem ;
    }

    .label_input {
        margin-top: 3%;
        width: 100%;
    }

    .search_menu {
        font-size: 0.8rem;
        height: 1.5rem;
        padding: 0.1rem 0.4rem;
    }

    .search_menu_btn {
        font-size: 0.8rem;
        padding: 0.1rem 0.4rem;
        height: 1.5rem;
    }

    #chart_datas {
        width: 100%;
        height: fit-content;
        position: relative;
        padding: 0rem;
        margin-top: 0.1%;
        margin-bottom: 0.5rem;
    }

    .input_wrp {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        height: fit-content;
        padding-left: 0.3rem;
    }

    .column{
        width: 100%;
    }

    .addRowBtn {
        width: auto;
        position: absolute;
        left: 95%;
        font-size: 1.2rem;
        background-color: var(--green);
        padding: 0.1rem .2rem;
        border-radius: 0%;
        top: 15%;
        left: 93%;
    }

    .form_file{
        font-size: 0.6rem;
    }

    .column label {
        font-size: 0.8rem;
        font-weight: 400;
        text-align: left;
    }

    .headerInputs{
        width: 95%;
        padding: 0rem 0.2rem;
    }

    .small-1-input {
        width: 70%;
        border: none;
        padding-left: 0px;
    }

    .headerInputs:hover, .headerInputs:focus {
        outline: 1px solid var(--orange);
        padding: 0rem 0.2rem;
    }

    #headerTableRows{
        display: none;
    }

    .addShopButton {
        font-size: 0.8rem;
    }


    .foodTableRow{
        display: none;
    }

    .dishDiv {
        text-align: center;
        width: 100%;
        font-size: 0.6rem;
        position: relative;
        padding: 0.1rem;
    }

    .dishImagePartner {
        width: 60px;
        height: auto;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .dishdiv_button {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 3.5rem;
        left: 85%;
    }

    #tableEditButton{
        padding: 0.1rem 0.2rem;
        font-size: 0.9rem;
        right: 0%;
    }

    #tableSaveButton{
        font-size: 0.7rem;
        margin-right: 0.2rem;
    }

    .deleteButton {
        padding: 0.1rem 0.2rem;
        font-size: 0.9rem;
    }

    .tablesSalesBtn {
        padding: 0.2rem 0.3rem;
        font-size: 0.8rem;
        margin-right: 0.8rem;
        transition: ease-in .2s;
    } 

}

@media (min-width:321px) and (max-width: 375px){
    .service_drop {
        position: absolute;
        min-width: 5rem;
        z-index: 5;
        top: 110%;
        left: 30%;
    }

    .service_drop_link {
        padding: 0.3rem 0.7rem;

        margin-bottom: 0.4px;
        font-size: 0.8rem;
    }

    .profile_content{
        position: relative;
        width: 100%;
        padding: 0rem 0.2rem;
    }

    .coming_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 0.5rem;
    }
    
    .grid_profile_one {
        width: 100%;
    }

    #profileImageSection {
        /* border: 5px solid green; */
    }

    .essential_image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    #profileImageInput {
        font-size: 0.7rem;
    }

    .profile_img_div {
        width: 35%;
    }

    .image_button {
        gap: 0.2rem;
        margin-top: 0.5rem;
    }

    .profilePicBtn {
        margin-top: 0px;
        padding: 0.2rem 0.3rem;
        width: 25%;
        font-size: 1rem;
    }

    .essential_details {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .essential_grid {
        width: 70%;
    }

    .essential_grid_content {
        display: grid;
        grid-template-columns: 1fr;
        height: -moz-fit-content;
        height: fit-content; 
        padding: 5px 10px;
        margin-bottom: 0.1rem; 
    }

    .content_input {
        padding: 0.2rem;
        font-size: 0.9rem;
    }

    .editprofileButton {
        right: -2%;
        padding: 0.1rem 0.3rem;
    }
    
    .editButton, .saveButton {
        padding: 0.2rem 0.3rem;
        font-size: 0.9rem;
        right: 1%;
    }

    .other_contacts_div {
        background: rgba(128, 128, 128, 0.118);
        margin-top: 1%;
        text-align: center;
        position: relative;
        margin-bottom: 0.5rem;
    }

    .other_titles {
        font-size: 0.9rem;
        padding: 0.1rem 0.2rem;
        margin-bottom: 0.1rem;
    }

    .other_contact_grid {
        padding: 0rem;
        gap: 3px;
        display: flex;
        flex-direction: column;
    }

    .other_contacts{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .other_ul{
        padding: 0px;
        margin-bottom: 0.5rem;
    }

    .other_li {
        margin-bottom: 1%;
        width: 17rem;
    }

    .other_contact_phone {
        font-size: 0.8rem;
        color: black;
    }

    .other_sub_titles{
        width: 70%;
    }

    .error-message{
        font-size: 0.8rem;
        margin-bottom: 0px;
        padding: 0.2rem 0rem;
    }

    .radioDivDiv {
        margin-top: 1%;
    }

    .other_service_radio {
        font-size: 2rem;
    }

    .other_services_list {
        display: inline-flex;
        gap: 0.2rem;
        padding: 5px;
    }

    .other_service_labels {
        font-size: 10px;
        margin-left: 0px;
    }

    label {
        font-size: 0.1rem;
        font-weight: 100;
    }

    .other_services_list{
        display: grid;
        grid-template-columns: repeat(3 ,1fr);
        row-gap: 0.9rem;
    }

    .radio_btn_div {
        flex-direction: column;
        justify-content: left;
    }

    .iconHeader {
        width: 100%;
        padding: 0.9rem 0.3rem;
        display: grid;
        justify-content: left;
    }

    .profile_section{
        align-items: start;
        width: 100%;
        margin-top: 10%;
    } 

    .orderButtonsDiv{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.2rem;
    }

    .orderButtons{
        font-size: 0.9rem;
        border:  1px solid var(--green);
        background: var(--green);
        color: white;
        transition: ease-in .2s;
    }

    .orderButtons:focus{
        color: var(--orange);
        border: 1px solid var(--orange);
        background: white;
    }

    .menu_table {
        width: 100%;
    }

    .h2TableTitle {
        font-size: 1rem;
        margin-bottom: 0rem;
    }

    #map99{
        width: '100%'
    }

    .addRestaurantButton {
        padding: 0.3rem 0.8rem;
        font-size: 0.8rem;
        margin-bottom: 1%;
        margin-top: 15%;
    }

    .vendorLocation {
        font-size: 0.7rem;
    }

    
    .forgotten{
        width: 95vw;
        margin-top: 10%;
    }

    .locationtableEdit {
        width: 25rem;
    }

    .tableEditLabels{
        margin-left: 0rem;
        text-align: left;
    }

    .tableBestIn{
        margin-left: 0.5rem;
    }

    .func_icons {
        font-size: 1.2rem;
        padding: 0.2rem 0.3rem;
    }

    .restaurantSelection{
        font-size: 0.8rem;
    }

    .choose_select{
        padding: 0.1rem 0.3rem ;
    }

    .label_input {
        margin-top: 3%;
        width: 100%;
    }

    .search_menu {
        font-size: 0.8rem;
        height: 1.5rem;
        padding: 0.1rem 0.4rem;
    }

    .search_menu_btn {
        font-size: 0.8rem;
        padding: 0.1rem 0.4rem;
        height: 1.5rem;
    }

    #chart_datas {
        width: 100%;
        height: fit-content;
        position: relative;
        padding: 0rem;
        margin-top: 0.1%;
        margin-bottom: 0.5rem;
    }

    .input_wrp {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        height: fit-content;
        padding-left: 0.3rem;
    }

    .column{
        width: 100%;
    }

    .addRowBtn {
        width: auto;
        position: absolute;
        left: 95%;
        font-size: 1.2rem;
        background-color: var(--green);
        padding: 0.1rem .2rem;
        border-radius: 0%;
        top: 15%;
        left: 93%;
    }

    .form_file{
        font-size: 0.6rem;
    }

    .column label {
        font-size: 0.8rem;
        font-weight: 400;
        text-align: left;
    }

    .headerInputs{
        width: 95%;
        padding: 0rem 0.2rem;
    }

    .small-1-input {
        width: 70%;
        border: none;
        padding-left: 0px;
    }

    .headerInputs:hover, .headerInputs:focus {
        outline: 1px solid var(--orange);
        padding: 0rem 0.2rem;
    }

    #headerTableRows{
        display: none;
    }

    .addShopButton {
        font-size: 0.8rem;
    }


    .foodTableRow{
        display: none;
    }

    .dishDiv {
        text-align: center;
        width: 100%;
        font-size: 0.6rem;
        position: relative;
        padding: 0.1rem;
    }

    .dishImagePartner {
        width: 60px;
        height: auto;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .dishdiv_button {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 3.5rem;
        left: 85%;
    }

    #tableEditButton{
        padding: 0.1rem 0.2rem;
        font-size: 0.9rem;
        right: 0%;
    }

    #tableSaveButton{
        font-size: 0.7rem;
        margin-right: 0.2rem;
    }

    .deleteButton {
        padding: 0.1rem 0.2rem;
        font-size: 0.9rem;
    }

    .tablesSalesBtn {
        padding: 0.2rem 0.3rem;
        font-size: 0.8rem;
        margin-right: 0.8rem;
        transition: ease-in .2s;
    } 
}

@media (min-width:376px) and (max-width: 425px){
    .service_drop {
        position: absolute;
        min-width: 5rem;
        z-index: 5;
        top: 110%;
        left: 30%;
    }

    .service_drop_link {
        padding: 0.3rem 0.7rem;

        margin-bottom: 0.4px;
        font-size: 0.8rem;
    }

    .profile_content{
        position: relative;
        width: 100%;
        padding: 0rem 0.2rem;
    }

    .coming_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 0.5rem;
    }
    
    .grid_profile_one {
        width: 100%;
    }

    #profileImageSection {
        /* border: 5px solid green; */
    }

    .essential_image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    #profileImageInput {
        font-size: 0.7rem;
    }

    .profile_img_div {
        width: 35%;
    }

    .image_button {
        gap: 0.2rem;
        margin-top: 0.5rem;
    }

    .profilePicBtn {
        margin-top: 0px;
        padding: 0.2rem 0.3rem;
        width: 25%;
        font-size: 1rem;
    }

    .essential_details {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .essential_grid {
        width: 70%;
    }

    .essential_grid_content {
        display: grid;
        grid-template-columns: 1fr;
        height: -moz-fit-content;
        height: fit-content; 
        padding: 5px 10px;
        margin-bottom: 0.1rem; 
    }

    .content_input {
        padding: 0.2rem;
        font-size: 0.9rem;
    }

    .editprofileButton {
        right: -2%;
        padding: 0.1rem 0.3rem;
    }
    
    .editButton, .saveButton {
        padding: 0.2rem 0.3rem;
        font-size: 0.9rem;
        right: 1%;
    }

    .other_contacts_div {
        background: rgba(128, 128, 128, 0.118);
        margin-top: 1%;
        text-align: center;
        position: relative;
        margin-bottom: 0.5rem;
    }

    .other_titles {
        font-size: 0.9rem;
        padding: 0.1rem 0.2rem;
        margin-bottom: 0.1rem;
    }

    .other_contact_grid {
        padding: 0rem;
        gap: 3px;
        display: flex;
        flex-direction: column;
    }

    .other_contacts{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .other_ul{
        padding: 0px;
        margin-bottom: 0.5rem;
    }

    .other_li {
        margin-bottom: 1%;
        width: 17rem;
    }

    .other_contact_phone {
        font-size: 0.8rem;
        color: black;
    }

    .other_sub_titles{
        width: 70%;
    }

    .error-message{
        font-size: 0.8rem;
        margin-bottom: 0px;
        padding: 0.2rem 0rem;
    }

    .radioDivDiv {
        margin-top: 1%;
    }

    .other_service_radio {
        font-size: 2rem;
    }

    .other_services_list {
        gap: 0.2rem;
        padding: 5px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 0.9rem;
    }
    .other_service_labels {
        font-size: 10px;
        margin-left: 0px;
    }

    .radio_btn_div {
        flex-direction: column;
        justify-content: left;
    }

    .iconHeader {
        width: 100%;
        padding: 0.9rem 0.3rem;
        display: grid;
        justify-content: left;
    }

    .profile_section{
        align-items: start;
        width: 100%;
        margin-top: 10%;
    } 

    .orderButtonsDiv{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.2rem;
    }

    .orderButtons{
        font-size: 0.9rem;
        border:  1px solid var(--green);
        background: var(--green);
        color: white;
        transition: ease-in .2s;
    }

    .orderButtons:focus{
        color: var(--orange);
        border: 1px solid var(--orange);
        background: white;
    }

    .menu_table {
        width: 100%;
    }

    .h2TableTitle {
        font-size: 1rem;
        margin-bottom: 0rem;
    }

    #map99{
        width: '100%'
    }

    .addRestaurantButton {
        padding: 0.3rem 0.8rem;
        font-size: 0.8rem;
        margin-bottom: 1%;
        margin-top: 15%;
    }

    .vendorLocation {
        font-size: 0.7rem;
    }

    
    .forgotten{
        width: 95vw;
        margin-top: 10%;
    }

    .locationtableEdit {
        width: 25rem;
    }

    .tableEditLabels{
        margin-left: 0rem;
        text-align: left;
    }

    .tableBestIn{
        margin-left: 0.5rem;
    }

    .func_icons {
        font-size: 1.2rem;
        padding: 0.2rem 0.3rem;
    }

    .restaurantSelection{
        font-size: 0.8rem;
    }

    .choose_select{
        padding: 0.1rem 0.3rem ;
    }

    .label_input {
        margin-top: 3%;
        width: 100%;
    }

    .search_menu {
        font-size: 0.8rem;
        height: 1.5rem;
        padding: 0.1rem 0.4rem;
    }

    .search_menu_btn {
        font-size: 0.8rem;
        padding: 0.1rem 0.4rem;
        height: 1.5rem;
    }

    #chart_datas {
        width: 100%;
        height: fit-content;
        position: relative;
        padding: 0rem;
        margin-top: 0.1%;
        margin-bottom: 0.5rem;
    }

    .input_wrp {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        height: fit-content;
        padding-left: 0.3rem;
    }

    .column{
        width: 100%;
    }

    .addRowBtn {
        width: auto;
        position: absolute;
        left: 95%;
        font-size: 1.2rem;
        background-color: var(--green);
        padding: 0.1rem .2rem;
        border-radius: 0%;
        top: 15%;
        left: 93%;
    }

    .form_file{
        font-size: 0.6rem;
    }

    .column label {
        font-size: 0.8rem;
        font-weight: 400;
        text-align: left;
    }

    .headerInputs{
        width: 95%;
        padding: 0rem 0.2rem;
    }

    .small-1-input {
        width: 70%;
        border: none;
        padding-left: 0px;
    }

    .headerInputs:hover, .headerInputs:focus {
        outline: 1px solid var(--orange);
        padding: 0rem 0.2rem;
    }

    #headerTableRows{
        display: none;
    }

    .addShopButton {
        font-size: 0.8rem;
    }


    .foodTableRow{
        display: none;
    }

    .dishDiv {
        text-align: center;
        width: 100%;
        font-size: 0.6rem;
        position: relative;
        padding: 0.1rem;
    }

    .dishImagePartner {
        width: 60px;
        height: auto;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .dishdiv_button {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 3.5rem;
        left: 85%;
    }

    #tableEditButton{
        padding: 0.1rem 0.2rem;
        font-size: 0.9rem;
        right: 0%;
    }

    #tableSaveButton{
        font-size: 0.7rem;
        margin-right: 0.2rem;
    }

    .deleteButton {
        padding: 0.1rem 0.2rem;
        font-size: 0.9rem;
    }

    .tablesSalesBtn {
        padding: 0.2rem 0.3rem;
        font-size: 0.8rem;
        margin-right: 0.8rem;
        transition: ease-in .2s;
    } 
}    


@media (min-width: 768px) and (max-width:1023px) {
    /* #profileImageSection{
        flex-direction: row;
    } */

    .profile_img_div {
        width: 40%;
        height: 70%;
    }
}