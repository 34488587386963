.OrdeersGrid{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    padding-left: 0.2rem;
    gap: 0.2rem;
}

.statusbutn{
    width: 100%;
}

.vendor-section-orders{
    padding: 0.3rem 0.2rem;
}


@media (min-width: 426px) and (max-width: 768px) {
    .OrdeersGrid{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        padding-left: 0.2rem;
        gap: 0.1rem;
    }
}