@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

.landing-fresh-food {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
}

.fresh_nav_position{
    position: relative;
    width: 100%;
    z-index: 1000;
}

.landing-fresh-foods-header {
    background-color: var(--orange);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
}

.fresh_bar{
    border: 1px solid;
    width: 50%;
    display: flex;
    flex-direction: row;
    border: none;
}

.fresh_bar_input {
    padding: 5px;
    border: none;
    width: 90%;
    transition: ease-in .2s;
}

.fresh_bar_input:hover,.fresh_bar_input:focus {
    color: var(--orange);
    cursor: pointer;
    padding-left: 10px;
}

.fresh_glass{
    width: 10%;
    background: var(--green);
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
}

.discoverTitle{
    margin-bottom: 2rem;
}

.fresh_glass:hover{
    color: var(--orange);
    outline: 1px solid var(--orange);
    cursor: pointer;
    background: white;
}

.fresh_bar_input:hover,.fresh_bar_input:focus {
    outline: 1px solid var(--orange);
    color: var(--orange);
}

.landing-fresh-foods-header .icons a {
    margin: 0 10px;
    text-decoration: none;
    color: #333;
}

.homeIcon{
    transition: ease-in .2s;
}

.homeIcon:hover{
    color: white;
}

.main-category{
    margin-top: 8%;
    width: 90%;
    padding: 0px;
}

.fresh-list{
    width: 100%;
    margin-bottom: 2%;
}

.fresh_page_heading{
    font-size: 25px;
    font-weight: 800;
    font-style: normal;
    color: var(--green);
    text-decoration: underline;
}

.fresh_category{
    border: none;
    outline: 1px solid #ccc;
}

.fresh_category {
    padding: 20px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    height: 15%;
    width: 100%;
}

.fresh_category li {
    list-style: none;
    cursor: pointer;
}

.fresh_link{
    padding: 10px 5px;
    border-bottom: 1px solid green; 
    font-size: 16px;
    transition: ease-in 0.2s;
    margin: 0px 10px;
    transition: ease-in .2s;
}

.fresh_link:hover, .fresh_link:focus{
    padding: 10px 5px;
    border: none;
    outline: 1px solid var(--orange);
    outline-offset: -2px; 
    color: white;
    background: var(--green);
}

.fresh-page-offers-section{
    width: 90%;
    margin-bottom: 2%;
}

.fresh-page-offers-title{
    font-size: 25px;
    font-weight: 600;
    padding: 5px 10px;
    margin-top: 1%;
    margin-bottom: 2%;
    font-style: normal;
    color: var(--green);
    text-decoration: underline; 
}

.offer-page-grid {
    display: grid;
    grid-template-columns: repeat( 5 ,1fr);
    gap: 10px;
}

.wholesale{
    border-top: 1px solid grey;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
}

.fresh-wholesale-title{
    margin-top: 2%;
}

.fresh-wholesale-para{
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 18px;
}

.wholesale-cta-button{
    margin-top: 0px;
    background-color: var(--green);
    border: none;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
}

.wholesale-cta-button:hover, .wholesale-cta-button:focus{
    background-color: white;
    outline: 1px solid var(--orange);
    color: var(--orange);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
}

.hero {
    background-image: url("../../assets/images/foodstocks.jpg");
    background-size: contain;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
}

.hero .cta-button {
    background-color: #f39c12;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}

.hero-text{
    background-color: #333;
    padding-bottom: 20px;
}


.main-category, .featured-products, .offers, .testimonials, .newsletter {
    padding: 20px;
}

.category-grid {
    /* display: flex;
    flex-direction: column; 
    justify-content: center;
    gap: 15px; */
    display: grid;
    grid-template-columns: repeat(5,1fr);
    column-gap: 10px;
    row-gap: 20px;
    background-color: white;
    color: #333;
}

.food-grid{
    display: grid;
    grid-template-columns: repeat(5 , 1fr);
    column-gap: 5px;
    row-gap: 10px;
    align-items: center;
}

.product-grid, .testimonial-grid {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    background-color: white;
    color: #333;
}

.product, .offer, .testimonial {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
}

.newsletter form {
    display: flex;
    gap: 10px;
}

.newsletter input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
}

.newsletter .cta-button {
    background-color: #f39c12;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.footer-links a {
    margin: 0 10px;
    text-decoration: none;
    color: #333;
}

.social-media {
    margin: 10px 0;
}

.contact-info {
    margin: 10px 0;
}

.payment-methods {
    margin: 10px 0;
}

@media (max-width: 320px){
    .landing-fresh-food{
        /* border: 5px solid green; */

        align-items: start;
        padding: 0rem 0.3rem;
        align-items: center;
        width: 100vw;
    } 

    .landing-fresh-foods-header{
        left: 0;
        top: 0;
        right: 0;
        height: 3rem;
        width: 100vw;
        padding: 2px;
    }

    .fresh_bar{
        width: 70%;
    }

    .fresh_glass{
          /* border: 5px solid green; */

        width: 16%;
        padding: 0.3rem;
    }

    .main-category{
        margin-top: 18%;
        padding: 0;
        width: 100vw;
        /* align-items: center; */
    }

    .fresh_page_heading{
        font-size: 1rem;
        font-weight: 600;
        padding-left: 2px;
    }

    .fresh_category {
        padding: 0rem 0rem;
        height: 1%;
        width: 100vw
    }

    .fresh_link {
        padding: 0.1rem 1px;
        border-bottom: none;
        font-size: 0.6rem;
        transition: ease-in 0.2s;
        margin: 0rem 0.2rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .discoverTitle {
        margin-bottom: 0.6rem;
        font-size: 1rem;
    }

    .category-grid {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2px;
        row-gap: 10px;
        padding-left: 2px;
    }

    .fresh-page-offers-section {
        /* border: 5px solid green; */

        width: 100%;
    }

    .fresh-page-offers-title {
        font-size: 1rem;
        font-weight: 600;
        padding: 0.1rem 0.2rem;
    }

    .offer-page-grid {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.3rem;
        row-gap: 0.5rem;
    }

    .fresh-wholesale-title {
        color: green;
    }

    .fresh-wholesale-para {
        margin-top: 1%;
        margin-bottom: 4%;
        font-size: 1rem;
        text-align: center;
    }

    .wholesale {
        width: 100%;
    }

    .footer-section {
        /* overflow: hidden;
        background: #151414;
        position: relative; */
        width: 100vw;
    }

    .fresh_bar_input {
        padding: 5px;
       font-size: 0.8rem;
    }

    .fresh_glass {
        width: 16%;
        padding: 0.2rem;
    }

    .main-category {
        padding: 3px;
        width: 100vw;
        margin-top: 15%;
    }

    .landing-fresh-food {
        padding: 0rem 0.1rem;
        width: 100vw;
    }

    .landing-fresh-foods-header {
        padding: 5px;
    }

    .fresh_page_heading{
        font-size: 1rem;
        font-weight: 600;
        padding-left: 2px;
    }

    .fresh_category {
        padding: 0rem 0rem;
        height: 1%;
        width: 99vw;
        margin-bottom: 0.5rem;
    }

    .fresh_link {
        padding: 0.1rem 0px;
        border-bottom: none;
        font-size: 0.6rem;
        transition: ease-in 0.2s;
        margin: 0rem 0.2rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fresh-page-offers-section {
        width: 100%;
        margin-bottom: 0%;
    }

    .fresh-page-offers-title {
        font-size: 1rem;
        padding: 3px 5px;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    .offer-page-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 2px;
    }

    .fresh-wholesale-title {
        margin-top: 1%;
        font-size: 1.2rem;
    }

    .fresh-wholesale-para {
        font-size: 0.9rem;
        text-align: center;
    }

    .wholesale-cta-button {
        padding: 5px 15px;
        font-size: 16px;
    }
}

@media (min-width: 321px) and (max-width: 375px){
    .fresh_bar_input {
        padding: 5px;
       font-size: 0.8rem;
    }

    .fresh_glass {
        width: 16%;
        padding: 0.2rem;
    }

    .fresh_nav_position {
        width: 100vw;
    }

    .main-category {
        padding: 3px;
        width: 100vw;
        margin-top: 11%;
    }

    .landing-fresh-food {
        padding: 0rem 0.1rem;
        width: 100vw;
    }

    .landing-fresh-foods-header {
        padding: 5px;
    }

    .fresh_page_heading{
        font-size: 1rem;
        font-weight: 600;
        padding-left: 2px;
    }

    .fresh_category {
        padding: 0rem 0rem;
        height: 1%;
        width: 99vw;
        margin-bottom: 0.5rem;
    }

    .fresh_link {
        padding: 0.1rem 0px;
        border-bottom: none;
        font-size: 0.6rem;
        transition: ease-in 0.2s;
        margin: 0rem 0.2rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .discoverTitle {
        margin-bottom: 0.6rem;
        font-size: 1rem;
    }

    .category-grid {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2px;
        row-gap: 5px;
    }

    .fresh-page-offers-section {
        width: 100%;
        margin-bottom: 0%;
    }

    .fresh-page-offers-title {
        font-size: 1rem;
        padding: 3px 5px;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    .offer-page-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 2px;
    }

    .fresh-wholesale-title {
        margin-top: 1%;
        font-size: 1.2rem;
    }

    .fresh-wholesale-para {
        font-size: 0.9rem;
        text-align: center;
    }

    .wholesale-cta-button {
        padding: 5px 15px;
        font-size: 16px;
    }
}


@media (min-width: 376px) and (max-width:425px){
    .landing-fresh-food{
        align-items: start;
        padding: 0rem 0.3rem;
        align-items: center;
    } 

    .fresh_nav_position{
       
    }

    .landing-fresh-foods-header{
        left: 0;
        top: 0;
        right: 0;
        height: 4rem;
        width: 100%;
        padding: 0.2rem;
    }

    .fresh_bar{
        width: 60%;
    }

    .fresh_glass{
        width: 16%;
        padding: 0.3rem;
    }

    .main-category{
        margin-top: 18%;
        width: 100vw;
        padding: 0;
        width: 100%;
        align-items: center;
    }

    .fresh_page_heading{
        font-size: 1rem;
        font-weight: 600;
    }

    .fresh_category {
        padding: 0rem 0rem;
        height: 1%;
        width: 98vw
    }

    .fresh-list {
        width: 100vw;
    }

    .fresh_link {
        padding: 0.1rem 1px;
        border-bottom: none;
        font-size: 0.6rem;
        transition: ease-in 0.2s;
        margin: 0rem 0.1rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .discoverTitle{
        font-size: 1rem;
        margin-bottom: 0.6rem;
        text-decoration: underline;
    }

    .category-grid {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5px;
        row-gap: 10px;
    }

    .fresh-page-offers-section {
        width: 100%;
    }

    .fresh-page-offers-title {
        font-size: 1rem;
        font-weight: 600;
        padding: 0.1rem 0.2rem;
    }

    .offer-page-grid {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.3rem;
        row-gap: 0.5rem;
    }

    .fresh-wholesale-title {
        color: green;
    }

    .fresh-wholesale-para {
        margin-top: 1%;
        margin-bottom: 4%;
        font-size: 1rem;
        text-align: center;
    }

    .wholesale {
        width: 100%;
    }

    .fresh-wholesale-title {
        margin-top: 1%;
        font-size: 1.2rem;
    }

    .fresh-wholesale-para {
        font-size: 0.9rem;
        text-align: center;
    }

    .wholesale-cta-button {
        padding: 5px 15px;
        font-size: 16px;
    }
}


@media (min-width: 769px) and (max-width: 1024px) {
    .main-category{
        margin-top: 5%;
        width: 100%;
        padding: 10px;
    }

    .fresh_page_heading {
        font-size: 20px;
    }

    .fresh_link {
        padding: 10px 5px;
        font-size: 0.8rem;
        margin: 0px 10px;
        text-align: center;
    }

    .fresh_category {
        padding: 10px;
        height: 10%;
    }

    .discoverTitle{
        font-size: 25px;
        margin-bottom: 1rem;
    }

    .category-grid {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 5px;
    }

    .fresh-page-offers-section {
        width: 100%;
        padding: 0px 0.3rem;
    }

    .fresh-page-offers-title {
        font-size: 20px;
        margin-bottom: 1rem;
    }

    .offer-page-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 15px;
        column-gap: 5px;
        padding: 0rem 0.2rem;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .main-category {
        margin-top: 4%;
        padding: 10px 20px;
    }

    .fresh_page_heading{
        font-size: 19px;
    }

    .fresh-list {
        width: 100%;
    }

    .fresh_link {
        padding: 0.1rem 1px;
        border-bottom: none;
        font-size: 1rem;
        transition: ease-in 0.2s;
        margin: 0rem 0.1rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid green;
    }

    .fresh_link:hover, .fresh_link:focus{
        padding: 0.1rem 0.2rem;
        border: none;
        outline: 1px solid var(--orange);
        outline-offset: -2px; 
        color: white;
        background: var(--green);
    }


    .category-grid {
        grid-template-columns: repeat(6, 1fr);
        column-gap: 5px;
    }

    .discoverTitle{
        font-size: 19px;
        margin-bottom: 1rem;
        text-decoration: underline;
    }

    .offer-page-grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        row-gap: 5px;
        column-gap: 5px;
    }

    .fresh-page-offers-title {
        font-size: 19px;
        margin-bottom: 1rem;
    }
}