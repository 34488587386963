@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --green:rgb(30, 165, 9);
  --orange: rgb(255,165,0);
}

*{
  box-sizing: border-box;
}

.fresh_original{
  margin-bottom: 5px;
  padding: 5px;
  font-size: 1em;
}

.fresh-original-strikethrough{
  position: relative;
  display: inline-block;
  font-size: 1.1em;
  font-weight: bold;
}

.fresh-original-strikethrough::before {
  content: "";
  position: absolute;
  top: 50%; 
  left: 0;
  right: 0;
  border-top: 2px solid; 
  border-color: inherit;
  transform: rotate(-5deg);
  color: red; 
}

/* whole dish card  */
.food-card {
  width: 250px; 
  height: auto;
  list-style: none; 
  border-radius: 8px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--orange);
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
}
  
.food-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* common name for all dish card contents */
.foodcontent{
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

  /* dish card image  */
.food-image {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
}

/* dish name */
.foodName{
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  font-style: normal;
  padding: 10px 0px;
}

/* dish card price */
.foodPrice {
  font-weight: 300;
  font-size: 18px;
  font-style: normal;
}

/* dish card restaurant */
.foodVendor{
  font-weight: 900;
  font-size: 60px;
  font-style: normal;
  color: white;
  background-color: white;
  text-transform: uppercase;
  padding: 5px 0px;
  margin-bottom: 10px;
}

/* add to cart button */
.foodAddToCart {
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  width: 80%;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 0px;
  background: var(--green);
}
  
.dishAddToCart:hover {
  background-color: white;
  color: var(--green);
  outline: 1px solid var(--orange);
  font-weight: 500;
  border: none;
}
  
/* dish card restaurant */
.dishRestaurant {
  font-size: 14px;
  color: #666;
}

.original-price1 {
  color: red;
}

.discounted-price {
  color: green;
  font-weight: bold;
}
.dish-image-wrapper1 {
  position: relative;
  display: inline-block;
}


.discounted-price-circle-land {
  position: absolute;
  top: -7%;
  right: -6%;
  background-color: red; 
  color: white;
  font-weight: normal;
  padding: 5px 5px;
  z-index: 10;
  text-align: center;
}

.original-price {
  text-decoration: line-through;
  color: #888;
  font-size: 0.9em;
}

@media (max-width: 320px){
  .dish-card{
    width: 100%;
  }

  .food-image{
    height: 7.5rem;
    border-radius: 0;
    width: 100%;
  }


  .fresh_original {
    padding: 3px;
    font-size: 0.8em;
  }

  .fresh-original-strikethrough {
    font-size: 1em;
  }

  .dishRestaurant {
    font-size: 0.7rem;
    padding: 3px 0px;
    margin-bottom: 5px;
  }

  .dishAddToCart {
    padding: 0.2rem 0.7rem;
    width: 70%;
    margin-bottom: 0rem;
    font-size: 0.7rem;
  }

  .average {
    font-size: 0.7rem;
  }
}

@media (min-width: 321px) and (max-width: 375px){
  .dishRestaurant {
    font-size: 0.7rem;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .dish-card{
    width: 12.8rem;
  }

  .food-image{
    height: 9rem;
    border-radius: 0;
  }

  .discounted-price-circle{
    padding: 0.1rem 0.1rem;
    font-size: 0.6rem;
    top: -9%;
    right: 1%;
  }

  .dishName{
    font-size: 0.7rem;
    margin-bottom: 0rem;
    padding: 0.4rem 0rem;
  }

  .fresh_original{
    margin-bottom: 0;
    padding: 0.1rem;
    font-size: 0.9rem;
  }

  .fresh-original-strikethrough{
    font-size: 0.9rem;
  }

  .dishRestaurant{
    margin-bottom: 0.3rem;
    font-size: 0.7rem;
  }

  .dishAddToCart{
    border-radius: 0;
    padding: 0.3rem 0.9rem;
    width: 80%;
    margin-bottom: 0rem;
    font-size: 0.8rem;
  }

  .average{
    font-size: 0.8rem;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .food-image {
    width: 100%;
    height: 150px;
  }

  .dishRestaurant {
    font-size: 12px;
    color: #666;
  }
}

@media (min-width:1025px) and (max-width:1440px){
  .food-image {
    height: 7rem;
  }
}