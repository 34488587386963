@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

#cartModal{
    height: 100vh;
    width: 100%;
}

#cartModalLabel{
    font-size: 1.5rem;
    font-weight: 800;
    font-style: normal;
    color: var(--green);
    text-decoration: underline;
}

#cartItems{
    padding-left: 0px;
}

.restaurantNameDiv{
    margin-bottom: 10px;
}

.restaurantName{
    font-weight: 600;
    font-size: 16px;
}

.itemDishName{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 50%;
}

.DishDetailSpan{
    font-size: 0.9rem;
}

.d-flex{
    border: 1px solid rgba(128, 128, 128, 0.393);
    margin-bottom: 2px;
    padding: 5px ;
}

.minus-plus{
    color: white;
    border: none; 
    padding: 0.5rem 1.2rem;
    border: none;
    color: white;
}

.minus{
    background: var(--orange);
    transition: ease-in .2s;
}

.minus:hover{
    background: white;
    color: var(--orange);
    outline: 1px solid var(--orange);
}

.menuDishQuantity{
    width: 1rem;
    text-align: center;
    font-size: 1rem;
}

.plus{
    background: var(--green);
    transition: ease-in .2s;
    margin-right: 1rem;
}

.plus:hover{
    background: white;
    color: var(--green);
    outline: 1px solid var(--green);
}

.menuDelete{
    background: red;
    color: white;
    padding: 0.5rem 1.2rem;
    border: none;
    transition: ease-in .2s;
}

.menuDelete:hover{
    background: white;
    color: red;
    outline: 1px solid red;
}

.itemTotal{
    font-weight: 400;
    font-size: 1.1rem;   
}

#totalPrice{
    font-size: 1.2rem; 
    color: var(--orange);
    font-weight: 600;
}

.bottomBtn{
    color: white;
    background: var(--green);
    border: none;
    padding: 0.5rem 1rem;
    transition: ease-in .2s;
}

.bottomBtn:hover{
    color: var(--orange);
    background: white;
    outline: 1px solid var(--orange);
}

/* cart icon */
.fa-shopping-cart{
    font-size: 20px;
    color: var(--green);
    transition: ease-in .2s;
}

.fa-shopping-cart:hover{
    color: white;
    cursor: pointer;
}

.cart-icon-wrapper {
    position: relative;
}

#cartCount {
    position: absolute;
    top: 13%; 
    right: 4.4%;
    font-weight: 600;
    font-size: 18px;
    color: red;
}

@media (max-width:320px){
    .fa-shopping-cart {
        font-size: 14px;
    }

    #cartCount {
        font-size: 0.8rem;
        font-weight: 500;
        top: 13%;
        right: 1%;
    }

    #itemsList{
        margin-top: 40%;
        width: 100vw;
        left: -2.5%;
    }

    #cartModalLabel{
        font-size: 1rem;
    }

    #cart-modal-body{
        padding: 5px;
    }

    .restaurantNameDiv{
        margin-bottom: 10px;
        font-size: 12px;
    }

    .restaurantName {
        font-size: 12px;
    }

    #cart-list-items{
        padding: 0;
        padding-left: 1px;
        margin-bottom: 0.3rem;
    }

    .DishDetailSpan {
        font-size: 0.7rem;
    }

    #cart-modal-controls{
        padding: 1px;
    }

    #cart-modal-minus{
        padding: 5px 7px;
        font-size: 11px;
        margin-right: 0.4rem;
    }

    .menuDishQuantity{
        font-size: 11px;
    }

    #cart-modal-plus{
        padding: 5px 7px;
        font-size: 11px;
        margin-right: 0.4rem;
    }

    #cart-modal-delete{
        padding: 5px;
        font-size: 11px;
    }

    .itemTotal {
        font-size: 1rem;
    }

    #totalPrice {
        font-size: 1.1rem;
    }

    .bottomBtn {
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
    }
}  

@media (min-width: 321px) and (max-width: 375px){
    #cartCount {
        top: 1%;
        right: 1%;
        font-size: 15px;
    }

    .fa-shopping-cart {
        font-size: 16px;
    }

    .modal-dialog {
        margin-top: 20%;
    }

    .modal-header {
        padding: 0.7rem ;
    }

    .modal-body {
        padding: 0.1rem 0.2rem;
    }

    .restaurantNameDiv {
        margin-bottom: 5px;
        font-size: 0.8rem;
    }

    .restaurantName {
        font-size: 0.8rem;
    }

    .d-flex{
        margin-bottom: 1px;
        padding: 1px ;
    }

    .itemDishName {
        grid-template-columns: 50% 50%;
        width: 50%;
    }

    .DishDetailSpan {
        font-size: 0.7rem;
    }

    #cartModalLabel {
        font-size: 1rem;
    }

    .menuDishQuantity {
        width: 0.5rem;
        font-size: 0.8rem;
    }

    .menuDelete {
        padding: 0.3rem 0.5rem;
        font-size: 0.8rem;
    }

    #cartItems {
        margin-bottom: 0.5rem;
    }

    .itemTotal {
        font-size: 0.9rem;
    }

    #totalPrice {
        font-size: 1rem;
    }

    .bottomBtn {
        padding: 0.3rem 0.7rem;
        font-size: 0.8rem;
    }
}


@media (min-width: 376px) and (max-width : 425px){
    /* .fa-shopping-cart{
        font-size: 1rem;
        position: absolute;
        right: 5%;
        top: 33%;
    } */

    .modal-body{
        padding: 2px;
    }

    .modal-header{
        padding: 0.5rem;
    }

    #cartCount{
        font-size: 1rem; 
        right: 3%;
        top: 4%; 
    }

    .restaurantNameDiv {
        margin-bottom: 5px;
        font-size: 0.9rem;
    }

    .restaurantName {
        font-size: 0.9rem;
    }

    .d-flex{
        margin-bottom: 1px;
        padding: 1px ;
    }

    #cartModalLabel {
        font-size: 1rem;
    }

    .menuDelete {
        padding: 0.3rem 0.5rem;
        font-size: 0.8rem;
    }

    #cartItems {
        margin-bottom: 0.5rem;
    }

    .itemTotal {
        font-size: 0.9rem;
    }

    #totalPrice {
        font-size: 0.9rem;
    }

    #cartCount2{
        font-size: 1rem; 
        right: 5%;
        top: 4%;
        position: absolute;
        color: red;
        font-weight: 600;
    }

    .menuDelete{
        padding: 0.3rem 1rem;
        
    }

    .plus {
        margin-right: 0.6rem;
    }

    .detailDishName{
        margin-right: 0.1rem;
    }

    .DishDetailSpan {
        font-size: 0.8rem;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    #cartCount{
        top: 1%; 
        right: 1.9%;
        font-size: 1rem;
    }

    .cartModalContent{
        width: 140%;
        margin-left: -20%;
    }
}

@media(min-width:1025px) and (max-width:1440px){
    #cartCount {
        position: absolute;
        top: 4%;
        right: 4.4%;
        font-weight: 600;
        font-size: 18px;
        color: red;
    }

    .cartModalContent{
        width: 100%;
    }
}