@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --green:rgb(30, 165, 9);
    --orange: rgb(255,165,0);
}

#locationModal{
    background: rgba(0, 0, 0, 0.338);
    overflow-y: hidden;
}

.modal-dialog{
    margin-top: 6%;
}

#locationModalLabel{
    font-size: 20px;
    font-weight: 800;
    font-style: normal;
    color: var(--green);
    text-decoration: underline;
}

.fromSpan{
    font-weight: 500;
    font-size: 18px;
    font-style: normal; 
}

#mapContainer{
    height: 40vh;
}

.useMylocation{
    margin-top: 5px;
}

.foodModalHeader{
    padding: 0.5rem 1rem;
}

.InputBtn{
    border: none;
    width: 25%;
    margin-bottom: 0px;
    background: var(--green);
    color: white;
    padding: 0.4rem 1rem;
}

.InputBtn:hover{
    background:white;
    color: orange;
    outline: 1px solid orange;
}


#customerLocation{
    margin-top: 0.2rem;
    width: 90%;
    margin-bottom: 0px;
    height: 2em;
    font-size: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    cursor: pointer;
}

.cancel_Ok_btn{
    color: white;
    background: var(--green);
    border: none;
    padding: 0.3rem 1rem;
    transition: ease-in .2s;
}

.cancel_Ok_btn:hover{
    color: var(--orange);
    background: white;
    outline: 1px solid orange;
}

.landingCancel{
    color: white;
    background: var(--green);
    border: none;
    padding: 0.3rem 1rem;
    transition: ease-in .2
}


@media (max-width:320px){
    .modal-dialog{
        margin-top: 0%;
    }

    .cartModalContent{
        width: 100vw;
        left: 0%;
    }

    #locationModalContent{
        left: -2.5%;
        margin-top: 20%;
    }

    #locationModalLabel{
        font-size: 1rem;
    }

    #locationModalBody{
        padding: 0.2rem;
    }

    #locationFromDiv {
        font-size: 13px;
    }

    .fromSpan{
        font-size: 15px;
    }

    .InputBtn {
        width: 100%;
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }

    #customerLocation {
        width: 100%;
        height: 2.3em;
        font-size: 0.9rem;
    }

    .cancel_Ok_btn {
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
    }

    .landingCancel {
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
    }
}

@media (min-width: 321px) and (max-width: 375px){
    #locationModalLabel {
        font-size: 1rem;
    }

    #locationFromDiv {
        font-size: 13px;
    }

    .fromSpan {
        font-size: 15px;
    }

    .InputBtn {
        width: 100%;
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }

    #customerLocation {
        width: 100%;
        margin-bottom: 0px;
        height: 2em;
    }

    .cancel_Ok_btn {
        padding: 0.3rem 0.8rem;
        font-size: 0.8rem;
    }

    .landingCancel {
        padding: 0.3rem 0.8rem;
        font-size: 0.8rem;
    }
}

@media (min-width: 376px) and  (max-width:480px) {
    .InputBtn {
        width: 100%;
    }

    #customerLocation {
        width: 100%;
    }
}

